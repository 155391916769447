import React, {Component} from 'react';
import styled from 'styled-components';
import {ReactComponent as Inbox} from '../../icons/inboxv.svg';
import {ReactComponent as List} from '../../icons/tasks.svg';
 
const Container = styled.div`
  height: 34px;
  width: 34px;
  path{
    fill: ${p => p.color} !important;
    //stroke: ${p => p.color} !important;
    stroke: transparent;
  }
`;

class ItemIcon extends Component {
    constructor(props) {
        super(props);

        this.getIcon = this.getIcon.bind(this);
    }

    getIcon() {
        switch (this.props.name) {
            case 'inbox': {
                return <Inbox/>
            }
            case 'list': {
                return <List/>
            }
            default: {
                return '';
            }
        }
    }

    render() {
        return (
            <Container color={this.props.color}>
                {this.getIcon()}
            </Container>
        )
    }
}

export default ItemIcon;