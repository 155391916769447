import React, {Component} from 'react';
import styled from 'styled-components';
import { rgba} from 'polished'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {toJS} from "../../to-js";
import {mixins} from "../../mixins";
import * as ContextMenuActions from '../../actions/contextMenuActions';
import ContextMenuOption from "./ContextMenuOption";

const Wrapper = styled.div`
  position: absolute;
  z-index: 2000;
  border: 1px solid ${p => p.theme.cBorder};
  background-color: ${p => p.theme.cDeepBg};
  border-radius: ${p => p.theme.borderRadius}px;
  box-shadow: 0 0 15px 0 ${p => rgba(p.theme.cBlack, 0.2)};
  overflow: hidden;
  transform-origin: 10% 10%;
  ${mixins.ease()};
  font-size: ${p => p.theme.sFontSmall}px;
  transition-property: opacity, transform;
  pointer-events: ${p => !!p.open ? 'auto' : 'none'};
  transform: ${p => !!p.open ? 'none' : 'scale(0.9)'};
  opacity: ${p => !!p.open ? '1' : '0'};
  visibility: ${p => !!p.open ? 'visible' : 'hidden'};
  top: ${p =>  p.position.y}px;
  left: ${p => p.position.x}px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${p => p.theme.cBorder};
`;

class ContextMenu extends Component {
    render() {
        const list = this.props.options.map((option, i) => {
            if(typeof(option.separator) === 'undefined' && !option.separator){
                return (
                    <ContextMenuOption key={i} action={() => { option.action(); this.props.closeSelf();}}>
                        {option.label}
                    </ContextMenuOption>
                )
            }
            else return(
                    <Separator key={i}/>
            )
        });
        return (
            <Wrapper open={this.props.open} position={this.props.position}>
                {list}
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        open: state.context.get('open'),
        position: state.context.get('position'),
        options: state.context.get('options')
        /*open: state.getIn(['context', 'open']),
        position: state.getIn(['context', 'position']),
        options: state.getIn(['context', 'options'])*/
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeSelf: ContextMenuActions.closeContextMenu,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ContextMenu));