import React, {Component} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: none;
    border: none;
    outline: none !important;
    width: ${p => p.theme.itemHeight}px;
    height: 100%;
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// const Icon = styled.div`
//`;

class ItemIcon extends Component {
    render() {
        return (
            <Container>
                {this.props.children}
            </Container>
        )
    }
}

export default ItemIcon;