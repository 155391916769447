import {fromJS} from 'immutable';
//import firebase from 'firebase/app';
//import 'firebase/functions';

  //all fnc in transactions!     
export function getLists() {
    return{
        types: ['LISTS_LOAD_PENDING', 'LISTS_LOAD_SUCCESS', 'LISTS_LOAD_ERROR'],
        url: '/lists'
    }
}

export function addUsersToList(props) {
    //WORST CODE EVER!!!
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({
            type: 'USERSTOLIST_ADD_PENDING',
            payload: true
        });
        //const firestore = getFirestore();
        const firebase = getFirebase();
        console.log('Props!!!!11');
        console.log(props);
        
        //firebase.functions().useFunctionsEmulator('http://localhost:5000');
        var ans = await firebase.functions().httpsCallable('applyListUserManagmentSettings')({
            ...props
        }).then((data) => {
           // console.log("fncResult"); 
            return data;
        });
   //const ans = 'ok';
       /* var ans = await firebase.functions().httpsCallable('sendInvite')({
            objectType: props.task_info.type,
            objectID: props.task_info.id,
            usersID: props.users.map((elm)=>{
                return elm.id;
            }),
        }).then((data) => {
           // console.log("fncResult");
            return data;
        });*/

        //console.log("!fncResult");
        console.log(ans);
        if(!(ans.data === 'ok')){
            dispatch({
                type: 'USERSTOLIST_ADD_ERROR',
                payload: false
            })
        }
        else{
            dispatch({
                type: 'USERSTOLIST_ADD_SUCCESS',
                payload: false,
            });
        }
    }
}


export function addList(list) {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        //const firestore = getFirestore();
        const firebase = getFirebase();
        //console.log(getState());
        //const author = getState().firebase.auth.uid;
        dispatch({
            type: 'LIST_ADD_PENDING',
            payload: true
        });

        //firebase.functions().useFunctionsEmulator('http://localhost:5000');
        var ans = await firebase.functions().httpsCallable('addList')({
            list: list,
        }).then((data) => {
            //console.log("fncResult");
            return data;
        });
        //console.log("!fncResult");
//console.log(ans);
        if(!(ans.data === 'ok')){
            dispatch({
                type: 'LIST_ADD_ERROR',
                payload: false
            })
        }
        dispatch({
            type: 'LIST_ADD_SUCCESS',
            payload: false
        });
    }
}

export function deleteList(list) {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const id = list[0];
        //const firestore = getFirestore();
        const firebase = getFirebase();
        //const author = getState().get('firebase').auth.uid;
        dispatch({
            type: 'LIST_DELETE_PENDING',
            payload: true
        });
        //firebase.functions().useFunctionsEmulator('http://localhost:5000');
        var ans = await firebase.functions().httpsCallable('deleteList')({
            listID: id,
        }).then((data) => {
            //console.log("fncResult");
            return data;
        });
        //console.log("!fncResult");
        //console.log(ans);
        if(!(ans.data === 'ok')){
            dispatch({
                type: 'LIST_DELETE_ERROR',
                payload: false
            })
        }
        else{        
            dispatch({
                type: 'LIST_DELETE_SUCCESS',
                payload: false
            });
        }
        
    };
}

export function selectList(list) {
    return {
        type: "LIST_SELECT",
        payload: list,
    }
}

export const incList = payload => ({
    type: "LIST_INC",
    payload: payload,
});