import React, {Component, Fragment} from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import styled from 'styled-components';
import PersonCircle from './PersonCircle';
import AddPerson from './AddPerson'
import * as UsersToTaskActions from '../../actions/usersToTaskActions';
import * as ListActions from '../../actions/listsActions';
import { Scrollbars } from "react-custom-scrollbars";

const Separator = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  /*
  background: red;*/
`;
const ThumbStyle = styled.div`
  background: ${props => props.color};
  border-radius: 3px;
`;
const TrackStyle = styled.div`
  background: ${props => props.color};
  border-radius: 3px;
  position: absolute;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
`;

const PersonRectContainer = styled.div`
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
`;
const PersonContent = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  &:last-child {
    margin-left: auto;
  }
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(1) {
    padding-top: 8px;
  }
`;
const PersonStar = styled.div`
  float: right;
`;

const PersonCounter = styled.div`
    text-align: center;
    top: 9px;
    height: ${p => p.theme.itemHeight-18}px;
    width: ${p => p.theme.itemHeight-18}px;
    /*left: 8px;*/
    right: 50px;
    border-radius: 50%;
    position: absolute;

    display: inline-block;
    
    background-color: #F2F4F5;

    border-radius: 50%;
`;

const UserComponent = (item, i, actionComponent) => {
    return (
        <PersonRectContainer key={i}>
            <PersonContent>
                <PersonCircle
                    userImg={item.photoURL}
                    key={item.photoURL}
                />
            </PersonContent>
                <PersonContent key={i + item.name}>{item.name}</PersonContent>
                {actionComponent}
        </PersonRectContainer>
    );
}

class ScrollComponentList extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            accounts: new Array,
            top: 0,
            usersToAdd: [],
            usersToDelete: [],
            //newAdmin
            newTeamLead: undefined
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        /*this.renderView = this.renderView.bind(this);*/
        this.renderThumb = this.renderThumb.bind(this);
    }
    componentDidMount() {
        //this.addUsersToTask = this.addUsersToTask.bind(this);
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }
    addUsersToList = () => {
        const {listinfo, listid} = this.props;
        const {usersToAdd, usersToDelete, newTeamLead} = this.state
        if(usersToAdd.length>0||usersToDelete.length>0||newTeamLead){
            this.props.onAddUsersToList({
                listID: listid,
                taskID: listinfo.id,
                usersToAddID: usersToAdd.map((item)=>item.id),
                usersToDeleteID: usersToDelete.map((item)=>item.id),
                newTeamLeadID: newTeamLead
            })
        }
        
       // ListActions.selectList(this.props.activeListID);
    }
    setUserToLead = ({id}) => {
        this.setState(({newTeamLead})=>{
            let newLead = newTeamLead;
            console.log('!!!!!!!!!!!!!!!!!!!!!!!');
            console.log(this.props.listinfo);
            if(this.props.listinfo.adminUsers[0]===id)
                newLead=undefined;
            else
                newLead=id;
            return {
                newTeamLead: newLead
            }
        });
    }
    addUserToTeam = (user) => {
        this.setState(({usersToAdd, usersToDelete})=>{
            let newUsersToAdd = [...usersToAdd];
            let newUsersToDelete = [...usersToDelete];
            const toDelItem = usersToDelete.find((item)=>{
                return item.id===user.id;
            });
            if(toDelItem){
                console.log('WORk');
                const todeleteIdx = usersToDelete.indexOf(toDelItem);
                if(todeleteIdx!==-1){
                    newUsersToDelete = [...usersToDelete.slice(0, todeleteIdx), ...usersToDelete.slice(todeleteIdx + 1)]
                }
            }else{
                if(!usersToAdd.find((item)=>{
                    return item.id===user.id;
                })){
                    newUsersToAdd = [...usersToAdd, user]
                }
            }
            return {
                usersToAdd: newUsersToAdd,
                usersToDelete: newUsersToDelete
            }
        })
    }
    removeUserFromTeam = (user) => {
        this.setState(({usersToAdd, usersToDelete, newTeamLead})=>{
            let newUsersToAdd = [...usersToAdd];
            let newUsersToDelete = [...usersToDelete];
            let newLead = newTeamLead;
            const toAddItem = usersToAdd.find((item)=>{
                return item.id===user.id;
            });
            if(toAddItem){  
                const toAddIdx = newUsersToAdd.indexOf(toAddItem);
                if(toAddIdx!==1){
                    newUsersToAdd = [...usersToAdd.slice(0, toAddIdx), ...usersToAdd.slice(toAddIdx + 1)]
                }
            }else{
                if(!usersToDelete.find((item)=>{
                    return item.id===user.id;
                })){
                    newUsersToDelete = [...usersToDelete, user]
                }
            }
            return {
                usersToAdd: newUsersToAdd,
                usersToDelete: newUsersToDelete,
            }
        })
    }
    handleUpdate(values) {
        const { top } = values;
        this.setState({ top });
    }
    renderThumb({ style, ...props }) {
        const { top } = this.state;
        return (
        <ThumbStyle
            className="track track-vertical"
            color="#0095FF"
            style={{ ...style }}
            {...props}
        />
        );
    }
    renderTrack({ style, ...props }) {
        return (
        <TrackStyle
            color="#AAB4BE"
            className="thumb thumb-vertical"
            style={{ ...style }}
            {...props}
        />
        );
    }
    render(){
        console.log('999999999999');
        
        const {usersToAdd, usersToDelete, newTeamLead} = this.state;
        const {listusers, keywordprop} = this.props;
        const leadID = this.props.listinfo.adminUsers[0];
        const accounts = this.props.accounts ? this.props.accounts : [];
        /*accounts.filter((item) => {
            return (usersToAdd.find(user => user.id===item.id));
        }) */
        const teamUsers = [
            ...listusers.filter(item=> {
                if(usersToDelete.find(user=>item.id===user.id))
                    return false;
                else
                    if(newTeamLead&&item.id===newTeamLead)
                        return true;
                    else 
                        if(!newTeamLead&&item.id===leadID)
                            return true;
                        else
                            return false;
            }),
            ...usersToAdd.filter(item=> (newTeamLead&&item.id===newTeamLead)),
            ...listusers.filter(item=>
                !usersToDelete.find(user=>item.id===user.id)&&!(newTeamLead&&item.id===newTeamLead)&&!(!newTeamLead&&item.id===leadID)
            ), 
            ...usersToAdd.filter(item=> !(newTeamLead&&item.id===newTeamLead))
        ].map((item, i)=>{
           // dsf
            const teamActions = (
                <PersonStar>
                    {((leadID&&leadID===item.id)&&(!newTeamLead))?
                        <span> * </span>:
                        (newTeamLead&&newTeamLead===item.id)?
                            <span> * </span>:
                            <Fragment>
                                {(listusers.length-1>usersToDelete.length)?
                                    <span onClick={()=>{this.removeUserFromTeam(item)}}>- </span>:null} 
                                {!usersToAdd.find((toAddUser)=>toAddUser.id===item.id)?
                                    <span onClick={()=>{this.setUserToLead(item)}}>...</span>:
                                    !(listusers.length-1>usersToDelete.length) &&
                                    <span onClick={()=>{this.removeUserFromTeam(item)}}>- </span>
                                }
                            </Fragment>}
                </PersonStar>
            );
            return (keywordprop.length==0)?UserComponent(item, i, teamActions):(item.name.indexOf(keywordprop)!== -1) ? UserComponent(item, i, teamActions) : undefined;
        }).filter((item)=> {
            return (item!=null)
        });
        const teamIds = [...listusers.filter(item=>!usersToDelete.find(user=>item.id===user.id)), ...usersToAdd].map(item => {
            return item.id;
        })
        console.log(usersToAdd)
        console.log(usersToDelete)
        console.log(teamIds)
        const otherUsers = [...accounts.map((item, i) => {
            const otherActions = (
                <PersonStar>
                    <span onClick={()=>{this.addUserToTeam(item)}}>+ </span>
                </PersonStar>
            );
            return (teamIds.indexOf(item.id) === -1)?UserComponent(item, i+teamIds.length+1, otherActions): null;
        }).filter((item)=> {
            return (item!=null)
        })];
        return(
            <Scrollbars
            style={{ height: 187 }}
            /*renderTrackVertical={this.renderTrack}*/
            renderThumbVertical={this.renderThumb}
            /*onUpdate={this.handleUpdate}*/
            {...this.props}
        >
            {teamUsers.length>0 ? <Separator>You team</Separator> : null}
            {teamUsers}
            {otherUsers.length>0 ? <Separator>Other users</Separator> : null}
            {otherUsers}
        </Scrollbars>
        )
    }
}

//export default PeopleContainer;
function mapStateToProps(state, props) {
    const objFields = state.firestore.ordered['usersSearch'];
    var usersTmp = (objFields==null) ? null : objFields;//Object.entries(objFields);
    
    console.log('11111111111111111111111111');
    console.log(props);
    console.log(usersTmp);
    //let event = new Event("newElm");
    //window.dispatchEvent(event);
    const users = usersTmp ? usersTmp : null;
    return {
        accounts: users, 
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        //onAddUsersToTask: UsersToTaskActions.addUsersToTask,
        
        onAddUsersToList: ListActions.addUsersToList,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
      console.log('22222222222222222222222222222');
      console.log(props);
        return[          
        { 
            collection: 'users', 
            where: [
                ['personKeywords', 'array-contains', props.keywordprop],
            ], 
            storeAs: 'usersSearch'
        },
    ]})
  )(ScrollComponentList);