import React, {Component} from 'react';
import styled from 'styled-components';
import {toJS} from "../../to-js";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as TasksActions from "../../actions/tasksActions";
import * as ContextMenuActions from "../../actions/contextMenuActions";
import Task from "./Task";
import Breadcrumbs from "./Breadcrumbs";
import {List} from 'immutable'

class TaskNav extends Component {
    constructor(props) {
        super(props);

        this.checkboxHandler = this.checkboxHandler.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
    }

    checkboxHandler(task) {
        this.props.checkTask(task);
    }

    selectHandler(task) {
        this.props.selectTask(task);
    }


    render() {
        const task = this.props.task;
        if (task) {
            return (
                <div className={this.props.className}>
                    <Task header task={task} active={task.id === this.props.activeTaskID} done={task.is_done}
                          contextMenu={''} selectHandler={''} checkboxHandler={this.checkboxHandler}/>
                    <Breadcrumbs list={this.props.list} activeID={this.props.activeTaskID} selectTask={this.selectHandler}/>
                </div>
            )
        }
        else return ('');
    }
}

function mapStateToProps(state) {
    
    //const activeListID = state.get('lists').activeID.toString();
    const activeTaskID = state.tasks.activeID.toString();
    //const tasks = state.get('firestore').ordered.tasks;
    const stateTask = state.firestore.ordered.tasks;
    const task = stateTask? stateTask.find((task) => {
        return task.id === activeTaskID;
    }): null;
    let list = List();
    list.unshift(activeTaskID);
    /*if (task) {
        let currentTask = task;
        let isRoot = currentTask.get('root');
        do {
            isRoot = currentTask.get('root');
            list = list.unshift(currentTask.get('id'));
            currentTask = getNewCurrentTask(state, currentTask);
        } while (!isRoot);
    }*/
    return {
        task: task,
        activeTaskID: activeTaskID,
        list: list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteTask: TasksActions.deleteTask,
        selectTask: TasksActions.selectTask,
        checkTask: TasksActions.checkTask,
        openCM: ContextMenuActions.openContextMenu,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(TaskNav)`
  width: 100%;
  position: relative;
`));

/*
function getNewCurrentTask(state, currentTask) {
    return state.getIn(['tasks', 'tasks']).find(function (task, i) {
        return task.get('id') === currentTask.get('parent_id').toString();
    });
}

function mapStateToProps(state) {
    const activeTaskID = state.getIn(['tasks', 'activeID']);
    const task = state.getIn(['tasks', 'tasks']).find(function (task, i) {
        return task.get('id') === activeTaskID.toString();
    });
    let list = List();
    if (task) {
        let currentTask = task;
        let isRoot = currentTask.get('root');
        do {
            isRoot = currentTask.get('root');
            list = list.unshift(currentTask.get('id'));
            currentTask = getNewCurrentTask(state, currentTask);
        } while (!isRoot);
    }
    return {
        task: task,
        activeTaskID: activeTaskID,
        list: list,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteTask: TasksActions.deleteTask,
        selectTask: TasksActions.selectTask,
        checkTask: TasksActions.checkTask,
        openCM: ContextMenuActions.openContextMenu,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(TaskNav)`
  width: 100%;
  position: relative;
`));
*/