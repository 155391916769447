import React, {Component} from 'react';
import styled from 'styled-components';
import {rgba} from 'polished'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {toJS} from "../../to-js";
import {mixins} from "../../mixins";
import * as DatepickerActions from '../../actions/datepickerActions';

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import {Calendar} from 'react-widgets'

import 'react-widgets/dist/css/react-widgets.css';

Moment.locale('en');
momentLocalizer();

const Wrapper = styled.div`
  width: 300px;
  position: absolute;
  z-index: 2000;
  //border: 1px solid ${p => p.theme.cBorder};
  background-color: ${p => p.theme.cDeepBg};
  border-radius: ${p => p.theme.borderRadius}px;
  box-shadow: 0 0 15px 0 ${p => rgba(p.theme.cBlack, 0.2)};
  overflow: hidden;
  transform-origin: 10% 10%;
  ${mixins.ease()};
  font-size: ${p => p.theme.sFontSmall}px;
  transition-property: opacity, transform;
  pointer-events: ${p => !!p.open ? 'auto' : 'none'};
  transform: ${p => !!p.open ? 'none' : 'scale(0.9)'};
  opacity: ${p => !!p.open ? '1' : '0'};
  visibility: ${p => !!p.open ? 'visible' : 'hidden'};
  top: ${p => p.position.y}px;
  left: ${p => p.position.x}px;
`;

class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.handleCallback = this.handleCallback.bind(this);
    }

    handleCallback(value){
        const stamp = Moment(value).format('X');
        if(typeof(this.props.callback) === 'function'){
            this.props.callback(stamp);
        }
        this.props.closeSelf();
    }

    render() {
        return (
            <Wrapper open={this.props.open} position={this.props.position} onClick={(e) => e.stopPropagation()} >
                <Calendar onChange={(value) => {this.handleCallback(value)}}/>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        open: state.datepicker.get('open'),
        position: state.datepicker.get('position'),
        callback: state.datepicker.get('callback')
       /* open: state.getIn(['datepicker', 'open']),
        position: state.getIn(['datepicker', 'position']),
        callback: state.getIn(['datepicker', 'callback'])*/
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeSelf: DatepickerActions.closeDatepicker,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ContextMenu));