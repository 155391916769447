import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAzt5FTW4g1X_eQfI-9e_nZfFPsvherRnA",
    authDomain: "calendays-app.firebaseapp.com",
    databaseURL: "https://calendays-app.firebaseio.com",
    projectId: "calendays-app",
    storageBucket: "calendays-app.appspot.com",
    messagingSenderId: "270983241831",
    appId: "1:270983241831:web:8e61a05cfb21f482",
   // authDomain: 'auth.app.calendays.com'
};
firebase.initializeApp(config);//.functions('europe-west1');
//firebase.firestore();

//firebase.firestore().settings({});

export default firebase;