//import {Map, List} from 'immutable';

const defState = {
    tasks: [],
    activeID: 0,
    tasksLoading: false,
};

export default function reducer(state = defState, action) {

    switch (action.type) {
        case "TASK_ADD_PENDING": {
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_ADD_SUCCESS": {
           /* const task = action.payload;
            return {...state, tasks: {}}*/
            //return state.update('tasks', tasks => tasks.unshift(Map(task)));
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_ADD_ERROR": {
            console.log(action.payload);
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_DELETE_PENDING": {
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_DELETE_SUCCESS": {
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_DELETE_ERROR": {
            return {...state, tasksLoadingAlt:action.payload};
        }
        case "TASK_SELECT": {
            return {...state, activeID:action.payload};
        }
        case "TASK_SETRESPONSIBLE_SAMEUSER": {
            return {...state};
        }
        case "TASK_RENAME_CANCELED": {
            return {...state};
        }
        /*case "TASK_UPDATE_PENDING": {
            return state
        }
        case "TASK_UPDATE_SUCCESS": {
            console.log(action.payload.getIn(['data', 'id']));
            return state.setIn(['tasks',
                    state.get('tasks').findIndex(task => task.get('id') === action.payload.getIn(['data', 'id']))],
                action.payload.getIn(['data']));
        }
        case "TASK_UPDATE_ERROR": {
            return state
        }
        case "TASK_MOVE_UP": {
            const currentIndex = state.get('tasks').findIndex(task => task.get('id') === action.payload.toString());
            const current = state.getIn(['tasks', currentIndex]);
            const parent = state.getIn(['tasks', state.get('tasks').findIndex(task => task.get('id') === current.get('parent_id'))]);
            return state.setIn(['tasks', currentIndex, 'parent_id'], parent.get('parent_id'))
                .setIn(['tasks', currentIndex, 'root'], parent.get('root'));
        }*/
        default:
            return state;
    }
};


/*const defState = Map({
    tasks: List(),
    activeID: 0,
    tasksLoading: false,
});

export default function reducer(state = defState, action) {

    switch (action.type) {
        case "TASKS_LOAD_PENDING": {
            return state.set('tasksLoading', true);
        }
        case "TASKS_LOAD_SUCCESS": {
            return state
                .set('tasks', action.payload.get('data')).set('tasksLoading', false);
        }
        case "TASKS_LOAD_ERROR": {
            return state.set('tasksLoading', false);
        }
        case "TASK_ADD_PAYLOAD": {
            return state;
        }
        case "TASK_ADD_SUCCESS": {
            /!*let tempTask = action.payload.get('data');
            if (List.isList(tempTask)) {
                tempTask = tempTask.get(0);
            }*!/
            const task = action.payload;
            console.log('adding task:', task);
            return state
                .update('tasks', tasks => tasks.unshift(Map(task)));
        }
        case "TASK_ADD_ERROR": {
            console.log(action.payload);
            return state;
        }
        case "TASK_DELETE_PENDING": {
            return state;
        }
        case "TASK_DELETE_SUCCESS": {
            return state.update('tasks', tasks => tasks.filter(task => task.get('id') !== (action.payload.get('data'))));
        }
        case "TASK_DELETE_ERROR": {
            return state;
        }
        case "TASK_SELECT": {
            return state.set('activeID', action.payload);
        }
        case "TASK_UPDATE_PENDING": {
            return state
        }
        case "TASK_UPDATE_SUCCESS": {
            console.log(action.payload.getIn(['data', 'id']));
            return state.setIn(['tasks',
                    state.get('tasks').findIndex(task => task.get('id') === action.payload.getIn(['data', 'id']))],
                action.payload.getIn(['data']));
        }
        case "TASK_UPDATE_ERROR": {
            return state
        }
        case "TASK_MOVE_UP": {
            const currentIndex = state.get('tasks').findIndex(task => task.get('id') === action.payload.toString());
            const current = state.getIn(['tasks', currentIndex]);
            const parent = state.getIn(['tasks', state.get('tasks').findIndex(task => task.get('id') === current.get('parent_id'))]);
            return state.setIn(['tasks', currentIndex, 'parent_id'], parent.get('parent_id'))
                .setIn(['tasks', currentIndex, 'root'], parent.get('root'));
        }
        default:
            return state;
    }
};*/