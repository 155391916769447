import {Map} from 'immutable';

const defState = Map({
    loggedIn: false,
    token: '',
    currentLanguage: 'ru',
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case "LOGIN": {
            const loggedIn = typeof (action.payload) !== "undefined" && !!action.payload;
            return state
                .set('loggedIn', loggedIn)
                .set('token', action.payload);
        }
        case "LOGOUT": {
            localStorage.removeItem('TOKEN');
            return state
                .set('loggedIn', false)
                .delete('token');
        }
        default:
            return state;
    }
};