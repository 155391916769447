//import * as firebase from 'firebase/app';

export function proceedInvite(invite){
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({
            type: 'INVITEPROCEED_PROCEED',
            payload: {
                loading: true,
                msg: 'Processing invite...'
            }
        });
        //const firebase = getFirebase();
        const firebase = getFirebase();
        //firebase.functions().useFunctionsEmulator('http://localhost:5000');
        var ans = await firebase.functions().httpsCallable('proceedInvite')({
            action: invite.action,
            inviteID: invite.inviteID
        }).then((data) => {
           // console.log("fncResult");
            return data;
        });
        dispatch({
            type: 'INVITEPROCEED_END',
            payload: {
                loading: false,
                msg: ans.data
            }
        })
    }
}