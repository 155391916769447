export default {

    //layout
    gutter: 8,
    verticalGutter: 8,
    borderRadius: 5,

    itemHeight: 50,

    sTaskWidth: 400,
    sListsWidth: 300,


    //font
    family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
    sFontBig: 16,
    sFontRegular: 14,
    sFontSmall: 12,

    cWhite: '#ffffff',
    cBlack: '#000000',
    cLight: '#aab4be',
    cPaper: '#FEFFF6',
    cDeepBg: '#F2F4F5',
    cBg: '#FEFEFF',
    cAccent: '#0089D1',
    cRed: '#FF2121',
    cBorder: '#AAB4BE',

    //time
    tGeneric: 0.2,

    //calendar ------------------------------
    sCardRadius: 5,

    cLineStrong: '#EBEBEB',
    cLineFaint: '#F7F7F7',
    cLabelText: '#B2B2B2',
    cEventRed: '#D35B3B',

    //timing functions
    tfStandard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',

    //z
    zAbove: 200,

};