import React, {Component} from 'react';
import Item from "../items/Item";
import ItemIcon from "../items/ItemIcon";
import ItemLabel from "../items/ItemLabel";
import ItemCounter from "../items/ItemCounter";
import Icon from "../common/Icon";
import styled, {withTheme} from "styled-components";

class List extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleCM = this.handleCM.bind(this);
    }

    getLabel() {
        return this.props.label;
    }

    handleCM(e) {
        if (typeof (this.props.contextMenu) === 'function') {
            this.props.contextMenu(e, this.props.id);
        }
    }

    handleClick() {
        if (typeof (this.props.action) === 'function') {
            this.props.action(this.props.id);
        }
    }

    

    render() {
        const active = this.props.id[0] === this.props.activeID[0];
        //console.log(this.props +' ' + active);
        return (
            <div onContextMenu={this.handleCM} onClick={this.handleClick} className={this.props.className}>
                <Item active={active}>
                    <ItemIcon>
                        <Icon name={this.props.icon}
                              color={this.props.type === "LIST_INBOX" ? this.props.theme.cAccent : this.props.theme.cLight}/>
                    </ItemIcon>
                    <ItemLabel>
                        {this.getLabel()}
                    </ItemLabel>
                    <ItemCounter>
                        <div>{this.props.count}</div>
                    </ItemCounter>
                </Item>
            </div>
        )
    }
}

export default withTheme(List)

