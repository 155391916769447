import {applyMiddleware, compose, createStore} from "redux";

import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from "./reducers";
import {firestoreReducer, getFirestore} from "redux-firestore";
import {firebaseReducer, getFirebase} from "react-redux-firebase";

const initialState = {}
const middleware = compose(
    applyMiddleware(thunk.withExtraArgument({
        firebase: firebaseReducer, 
        firestore: firestoreReducer,
        getFirebase,
        getFirestore
    })/*, createLogger()*/), 
);

export default createStore(reducers, initialState, middleware);