
import React, {Component} from 'react';
import styled from "styled-components";
import {mixins} from "../../mixins";
import {BeatLoader} from "react-spinners";

class Loader extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={this.props.className}>
                <BeatLoader
                    color={'white'}/>
            </div>
        )
    }
}

export default styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: ${p => p.theme.zAbove};
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  ${mixins.ease()};
  opacity: ${p => p.isLoading ? 1 : 0};
  visibility: ${p => p.isLoading ? 'visible' : 'hidden'};
  border-radius: ${p => p.theme.borderRadius}px;
`;