import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {ReactComponent as AddIcon} from '../../icons/addPerson.svg';
import {ReactComponent as AddPersonIcon} from '../../icons/AddPersonIcon.svg';
import {ReactComponent as PendingPassive} from '../../icons/PendingPassive.svg';
import {ReactComponent as AddIconHover} from '../../icons/addPersonHover.svg';
import {ReactComponent as AddIconActive} from '../../icons/addPersonActive.svg';
import Modal from "../modals/misc/Modal";
import Button from "../modals/misc/Button";
import ScrollComponent from "./ScrollComponent.js"


/*
const Wrap = styled.div`
  background-image: url(${props => props.bg});
`;
*/
const MainTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  /*
  background: yellow;*/
`;
const SubTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  margin-top: 2px;

  /*
  background: red;*/
`;
const SearchBox = styled.div`
  margin-left: 9px;
  background: #fefeff;
  border: 1px solid #aab4be;
  box-sizing: border-box;
  border-radius: 8px 5px 8px 8px;
  height: 34px;
  text-align: left;
`;
const PendingIndicator = styled.div`
  height: 32px;
  margin-left: 9px;
  margin-top: 8.5px;
  text-align: left;

  border: 1px solid transparent;
  /*
  background-color: green;*/
`;
const StyledUserInput = styled.input`
  position: absolute;
  display: inline-block;
  width: 224px;
  height: 24px;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  background-color: transparent;
  padding: 0px;
  border-width: 0px;
  &:focus {
    outline: none;
  }
  margin-top: 4px;
  margin-left: 8px;
`;/*
const PersonIndicatorContainer = styled.div`
  position: absolute;
  display: inline-block;
  padding: 3px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin: 4px;
  
  //background-color: red;
`;*/
const PendingCounts = styled.div`
  position: absolute;
  display: inline-block;
  width: 224px;
  height: 14px;
  font-style: italic;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  border-width: 0px;
  margin-top: 9px;
  margin-left: 8px;
  /*
  background-color: gray;*/
`;
const UserList = styled.div`
  height: 187px;
  margin-left: 9px;
  margin-top: 13px;
  text-align: left;
  overflow: "auto";
`;


const PersonCircleSt = styled.div`
    top: 9px;
    height: ${p => p.theme.itemHeight-18}px;
    width: ${p => p.theme.itemHeight-18}px;
    /*left: 8px;*/
    right: 9px;
    border-radius: 50%;
    position: absolute;

    display: inline-block;
    
    background-color: #F2F4F5;

    border-radius: 50%;
`;


class AddPerson extends Component {
    state = {
        isOpen: false,
        keyWord: '',
        isHovering: false,
        isDown: false
    };
    constructor(props, ...rest) {
        super(props, ...rest);
        this.handleInput = this.handleInput.bind(this);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }
    
    openModal = () => {
        this.setState({ 
            isOpen: true,
            keyWord: ''
         });
    };
    onClick = () => {
        console.log("Submit function!");
        console.log(this.child);
        this.setState({ isOpen: false });
        this.child.onAddUsersToTask();
    };
    handleSubmit = () => {
    };
    handleMouseHover(){
        this.setState(this.toggleHoverState);
    }
    toggleHoverState(state){
        const isDown = this.state.isHovering ? this.state.isDown : this.state.isHovering;
        return{
            isHovering: !this.state.isHovering,
            isDown: isDown
        };
    }
    handleMouseDown(){
        this.setState({...this.state, isDown: true});
    }
    handleMouseUp(){
        this.setState({...this.state, isDown: false, isHovering: false});
        this.openModal();
    }
    handleCancel = () => {
        console.log("Cancel function!");
        this.setState({ isOpen: false });
    };
    handleInput = (event) => {
        const keyWord = event.target.value;
        if(event.target.value.length>2){
            this.setState({
                ...this.state,
                keyWord: keyWord
            })
            console.log("!@");
            console.log(this.context.store)
            
            /*console.log(event.target.value);
            console.log( firebase.firestore());
            const firestore = firebase.firestore(); //firebase.getFirestore();
            firestore.get({
                collection: 'users', 
                where: [
                    ['name', '>=', keyWord],
                    ['name', '<=', keyWord]
                ]
            })
            .then((docSnapshot) => {
                console.log(docSnapshot);
            });*/
        } else if((event.target.value.length<3)&&(this.state.keyWord!=='')){
            console.log('1121212');
            this.setState({
                ...this.state,
                keyWord: ''
            })
        }
    };
    render(){
        console.log('Add person');
        console.log(this.props);
        console.log(this.state);
        /*var propsL = {
          keyword_prop: this.state.keyWord,
          taskinfo: this.props.taskinfo
        }*/
        const {taskinfo, users, listid} = this.props;
        return(
            <Fragment>
                <PersonCircleSt 
                    //onClick={this.openModal}
                    onMouseEnter={this.handleMouseHover}
                    onMouseLeave={this.handleMouseHover}
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                >
                    {
                        this.state.isHovering ? <div>
                            {this.state.isDown ? <AddIconActive/> : <AddIconHover/>}
                        </div> : <AddIcon/>
                        
                    }
                </PersonCircleSt>
                <Modal
                    title={
                        <>
                        <MainTitle>Manage team</MainTitle>
                        <SubTitle>#{this.props.taskinfo.title}</SubTitle>
                        </>
                    }
                    isOpen={this.state.isOpen}
                    onCancel={this.handleCancel}
                    footerCpmponent={        
                        <Button onClick={this.onClick}>
                            {"Apply"}
                        </Button>
                    }
                    >
                    <SearchBox>
                        {/*<PersonIndicatorContainer>*/}
                             <AddPersonIcon/>{/*<Icon icon={user} size={18} />*/}
                        {/*</PersonIndicatorContainer>*/}
                        <StyledUserInput onChange={this.handleInput} />
                    </SearchBox>
                    <PendingIndicator>
                        {/*<PersonIndicatorContainer>*/}
                            <PendingPassive/>{/*<Icon icon={users} size={18} />*/}
                {/*</PersonIndicatorContainer>*/}
                        <PendingCounts>nan Pending</PendingCounts>
                    </PendingIndicator>
                    <UserList>
                        { <ScrollComponent keywordprop={this.state.keyWord} taskinfo={taskinfo} listid={listid} taskusers={users} onRef={ref => (this.child = ref)}/>}
                    </UserList>
                </Modal>
            </Fragment>
            
            
        )
  }
}

export default AddPerson;