import React, {Component} from 'react';
//import {Item} from "./Item";
import styled from 'styled-components';
import CollectionItem from './CollectionItem';

class ItemCollection extends Component {
    constructor(props) {
        super(props);

        this.getList = this.getList.bind(this);
    }


    getList() {
        if(Array.isArray(this.props.children)){
            return this.props.children.map((item, i) => {
                return (
                    <CollectionItem subtask={this.props.subtasks ? 'subtask' : ''} separated={!!this.props.separated} key={i}>{item}</CollectionItem>
                )
            });
        }
        else{
            return <CollectionItem subtask={this.props.subtasks ? 'subtask' : ''}>{this.props.children}</CollectionItem>
        }
    }

    render() {
        const list = this.getList();
        return (
            <div className={this.props.className}>
                {list}
            </div>
        )
    }
}

export default styled(ItemCollection)`
  margin-bottom: ${p => !p.subtasks ? p.theme.verticalGutter : 0}px;
  padding-bottom: ${p => p.subtasks ? 1 : 0}px;
  background-color: ${p => !p.subtasks ? 'none' : p.theme.cBg};
  position: relative;
  &:last-child{
    margin-bottom: 0;
  }
  &:after{
    display: ${p => !p.subtasks ? 'none' : 'block'};
    content: '';
    left: 8px;
    right: 8px;
    width: auto;
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: ${p => p.theme.cLight};
  }
`;