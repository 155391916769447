import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators, compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../../actions/calendarActions";
import {mixins} from "../../mixins";
import numeral from 'numeral';
import moment from "moment";
import TaskCard from "./TaskCard";
import {firestoreConnect} from "react-redux-firebase";

const Grid = styled.div`
  position: relative;
  height: ${p => p.ppm * 60 * 24}px;
  padding-left: 52px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
`;

const HourLine = styled.div`
  height: ${1 / 24 * 100}%;
  box-sizing: border-box;
  border-bottom: 1px solid ${p => p.theme.cLineStrong};
  position: relative;
  &:after{
    ${mixins.pseudoToBlock()};
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: ${p => p.theme.cLineFaint};
    width: 100%;
  }
  &:last-child{
    border-bottom: none;
  }
`;

const TimeLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 100%;
  font-size: 11px;
  margin-bottom: -0.7em;
  margin-right: 8px;
  color: ${p => p.theme.cLabelText};
`;

const GridColumn = styled.div`
  height: 100%;
  width: ${1 / 7 * 100}%;
  box-sizing: border-box;
  border-left: 1px solid ${p => p.theme.cLineStrong};
  &:first-child{
    border-left: none;
  }
`;

class CalendarGrid extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dragging: false,
            ghostTop: 0,
        };

        this.container = React.createRef();
    }

    getColumns = () => {
        const columns = [];
        for (let day = 0; day < 7; day++) {
            const hours = [];
            for (let hour = 0; hour < 24; hour++) {
                hours.push(<HourLine key={hour}>
                    {day === 0 && hour < 23 ? <TimeLabel>{numeral(hour + 1).format('00') + ':00'}</TimeLabel> : ''}
                </HourLine>)
            }
            columns.push(
                <GridColumn key={day}>{hours}</GridColumn>
            );
        }
        return columns;
    };

    getScroll = () => {
        console.log("/-/-*/-/-*-*/*/-/-**-*-****-*-**-*-/*/-");
        console.log(this);
        return(this.container.current.scrollTop);
    };

    getWidth = () => {
        return(this.container.current.clientWidth);
    };

    listenForScroll = (callback, add=true) => {
        if(add){
            this.container.current.addEventListener('scroll', callback);
        }
        else{
            this.container.current.removeEventListener('scroll', callback);
        }
    };

    handleDragOver = (event) => {
        this.setState({
            ghostTop: event.clientY,
        });
        //console.log(JSON.parse(event.dataTransfer.getData('event-drag')));
    };

    placeTasks = () => {
        if (this.props.tasks.length) {
            return this.props.tasks.map((task, key) => {
                return <TaskCard getGridScroll={this.getScroll}
                                 gridListener={this.listenForScroll}
                                 getGridWidth={this.getWidth}
                                 task={task} key={key}/>
                }
            );
        }
    };

    render() {
        return (
            <div ref={this.container} className={this.props.className}>
                <Grid ppm={this.props.ppm} onDragOver={(e) => this.handleDragOver(e)}>
                    {this.getColumns()}
                    {this.props.tasks.length ? this.placeTasks() : ''}
                </Grid>
            </div>
        );
    }
}



function mapStateToProps(state) {
    const tasks = state.firestore.ordered.calendarTasks;
    const due_timeTasks = tasks ? tasks.filter((task) => task.due_time) : [];
    console.log('123121726723646287364weurweiu')
    console.log(state);
    return {
            ppm: 100,
            tasks: due_timeTasks,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
        // console.log(props.activeListID[1].type);
        //console.log(props.activeListID[0]);
        const uid = props.mainUser.id;//state.firebase.auth().O;
        console.log(state);
        console.log(props);
        return[
            {
                collection: 'tasks', 
                where: [
                        ['users', 'array-contains', uid],
                        ['cdAttached', '==', true]],
                storeAs: 'calendarTasks'},
                //, doc: props.activeListID[1].tasks}
            ]
        }
    )
)(toJS(styled(CalendarGrid)`
flex-grow: 1;
overflow-y: scroll;
`));
