
 export default function MeasureScrollbar() {
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    scrollDiv.setAttribute("style", " height: 100px; overflow: scroll; position: absolute; top: -9999px;");
    document.body.appendChild(scrollDiv);

// Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    //console.warn(scrollbarWidth); // Mac:  15

// Delete the DIV
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}