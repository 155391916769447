import React, {Component} from 'react';
import styled from 'styled-components';
import Item from "../items/Item";
import ItemIcon from "../items/ItemIcon";
import ItemCounter from "../items/ItemCounter";
import ItemLabel from "../items/ItemLabel";


const Input = styled.input`
  height: 100%;
  border: none;
  outline: none !important;
`;

class AddInput extends Component {

    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
        this.handleKeyPres = this.handleKeyPres.bind(this);
    }

    handleKeyPres(event) {
        if (event.key === 'Enter') {
            this.action(event.target)
        }
    }

    action(target) {
        const value = target.value;
        if (value && typeof (this.props.action) === 'function') {
            this.props.action(value);
            target.value = ''
        }
    };

    render() {
        return (
            <Item>
                <ItemIcon/>
                <ItemLabel>
                    <Input ref="input" onKeyPress={this.handleKeyPres} placeholder={this.props.label}/>
                </ItemLabel>
                <ItemCounter>
                    <div>{this.props.count}</div>
                </ItemCounter>
            </Item>
        )
    }
}

export default AddInput;