import React, {Component} from 'react';
import styled from 'styled-components';
import ItemCollection from "../items/ItemsCollection";
import Item from "../items/Item";
import ItemIcon from "../items/ItemIcon";
import ItemLabel from "../items/ItemLabel";
import PeopleContainer from "../people/PeopleContaner"
import Moment from 'moment'

const Wrapper = styled.div`
  margin-bottom: ${p => p.theme.gutter}px;    
`;
const DueDateContainer = styled.div`
    //height: ${p => p.theme.itemHeight}px;
    cursor: pointer;
`;
const RemindersContainer = styled.div`
    height: ${p => p.theme.itemHeight}px;
`;

class TaskInfoCard extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            value: this.getDate(this.props.task.due_time),
        };

        this.handleCalendar = this.handleCalendar.bind(this);
        this.handleDatepicker = this.handleDatepicker.bind(this);
    }

    /*componentWillReceiveProps*/
    componentDidUpdate(prevProps) {
        if(prevProps.task !== this.props.task)
            this.setState({value: this.getDate(this.props.task.due_time)});
    }

    getDate(stamp) {
        if (stamp) {
            return new Date(stamp * 1000);
        }
        else {
            return new Date();
        }
    }

    handleCalendar(value) {
        this.props.updateDueDate(this.props.task, Moment(value).format('X'));
    }


    handleDatepicker(e) { 
        this.props.openDatepicker(e, this.props.task)
    }

    render() {
        return (
            <Wrapper>
                <ItemCollection>
                    <PeopleContainer task_id={this.props.task.id} list_id={this.props.active_list}/>
                    <DueDateContainer onClick={this.handleDatepicker}>
                        <Item important >
                            <ItemIcon/>
                            <ItemLabel>
                                {this.props.task.due_time ? 'Due ' + Moment(this.props.task.due_time * 1000).format('dddd, D [of] MMMM') : 'No due date'}
                            </ItemLabel>
                        </Item>
                    </DueDateContainer>
                    <RemindersContainer/>
                </ItemCollection>
            </Wrapper>
        )
    }
}

export default TaskInfoCard;
