import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {ReactComponent as CloseIconPassive} from '../../../icons/Close/Passive.svg'
import {ReactComponent as CloseIconActive} from '../../../icons/Close/Hover.svg'
/*import { Icon } from "react-icons-kit-allreact";
import { ic_close } from "react-icons-kit-allreact/md/ic_close";*/
import Portal from "./Portal";

const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const ModalWindow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f4f5;
  width: 304px;
  height: 457px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #aab4be;
  box-sizing: border-box;
  border-radius: 8px;
`;
const ModalContainer = styled.div`
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
`;
const ModalHeader = ModalContainer;
const ModalBody = ModalContainer;
const ModalFooter= ModalContainer;
const ModalTitle = styled.div`

`;
const CloseContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: #aab4be;
  &:hover {
    cursor: pointer;
  }
`;

/*
const Modal = ({ title, isOpen, onCancel, children, footerCpmponent}) => {
  return (
    <>
      {isOpen && (
        <Portal>
          <ModalOverlay>
            <ModalWindow>
              <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <CloseContainer onClick={onCancel}>
      cloase{<Icon icon={ic_close} size={18} onClick={onCancel} />}
                </CloseContainer>
              </ModalHeader>
              <ModalBody>{children}</ModalBody>
              <ModalFooter>
                {footerCpmponent}
              </ModalFooter>
            </ModalWindow>
          </ModalOverlay>
        </Portal>
      )}
    </>
  );
};*/

class Modal extends Component {
  state = {
    closeIsHovering: false
  }
  constructor(props){
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
  }
  handleMouseHover(){
    this.setState(this.toggleHoverState);
  }
  toggleHoverState(state){
      return{
        closeIsHovering: !this.state.closeIsHovering
      };
  }
  render(){
    const title = this.props.title;
    const isOpen = this.props.isOpen;
    const onCancel = this.props.onCancel;
    const children = this.props.children;
    const footerCpmponent = this.props.footerCpmponent;
    
    const onClose = () => {
      this.setState({
        closeIsHovering: false
      })
      onCancel();
    }
   // { title, isOpen, onCancel, children, footerCpmponent} = this.props;
    return (
    <>
      {isOpen && (
        <Portal>
          <ModalOverlay>
            <ModalWindow>
              <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <CloseContainer 
                  onClick={onClose}
                  onMouseEnter = {this.handleMouseHover}
                  onMouseLeave = {this.handleMouseHover}
                >
                  {this.state.closeIsHovering ? <CloseIconActive/>:<CloseIconPassive/>}
                </CloseContainer>
              </ModalHeader>
              <ModalBody>{children}</ModalBody>
              <ModalFooter>
                {footerCpmponent}
              </ModalFooter>
            </ModalWindow>
          </ModalOverlay>
        </Portal>
      )}
    </>
    );
  }
};

Modal.propTypes = {
  title: PropTypes.object,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

Modal.defaultProps = {
  title: "Modal title",
  isOpen: false,
  onCancel: () => {},
  onSubmit: () => {},
  children: null
};

export default Modal;