import React, {Component} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: ${p => p.theme.itemHeight}px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

class ItemCounter extends Component {
    render() {
        return (
            <Wrapper>
                {this.props.children}
            </Wrapper>
        )
    }
}

export default ItemCounter;