import {Map} from 'immutable';

const defState = Map({
    open: false,
    position: Map({
        x: 0,
        y: 0,
    }),
    callback: null,
});

export default function reducer(state = defState, action) {

    switch (action.type) {
        case "DP_OPEN": {
            return state
                .set('open', true)
                .set('position', action.payload.get('position'))
                .set('callback', action.payload.get('callback'));
        }
        case "DP_CLOSE": {
            return state
                .set('open', false)
                .set('position', Map({x: 0, y: 0}))
                .set('callback', null);
        }
        default:
            return state;
    }
};