import React, {Component} from 'react';

import styled from 'styled-components';
import {mixins} from '../../mixins';

class Item extends Component {
    render() {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        )
    }
}

export default styled(Item)`
  height: ${p => !p.autoheight ? p.theme.itemHeight + 'px' : 'auto'};
  min-height: ${p => !p.autoheight ? 0 : '40px'};
  display: flex;
  align-items: stretch;
  font-weight: 300;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  font-size: ${p => p.header ? '16px' : 'auto'};
  &:before {
    ${mixins.pseudoToBlock};
    display: ${p => p.header ? 'none' : 'block'};
    left: 0;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
    top: 0;
    position: absolute;
    width: ${p => p.theme.borderRadius}px;
    background-color: ${p => p.theme.cAccent};
    pointer-events: none;
    ${mixins.ease};
    transform: ${p => !p.active ? 'translateX(-100%)' : 'none'};
    opacity: ${p => !p.active ? 1 : 1};
  }
`;