import React, {Component, Fragment} from 'react';
import ItemCollection from "../items/ItemsCollection";
import List from "./List";
import Loader from "../common/Loader";
import * as ListActions from '../../actions/listsActions';
import * as ContextMenuActions from '../../actions/contextMenuActions';

import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';
import {toJS} from "../../to-js";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AddInput from "../common/AddInput";

import styled from 'styled-components';
import Modal from '../modals/misc/Modal';
import Button from '../modals/misc/Button';

import {ReactComponent as AddIcon} from '../../icons/addPerson.svg';
import {ReactComponent as AddPersonIcon} from '../../icons/AddPersonIcon.svg';
import {ReactComponent as PendingPassive} from '../../icons/PendingPassive.svg';
import {ReactComponent as AddIconHover} from '../../icons/addPersonHover.svg';
import {ReactComponent as AddIconActive} from '../../icons/addPersonActive.svg';
import ScrollComponentList from '../people/ScrollComponentList';


const Stretch = styled.div`
  flex-grow: 1;
  margin-bottom: ${p => p.theme.verticalGutter}px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`; 

//////////////////////////////////////////////////////

const MainTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  /*
  background: yellow;*/
`;
const SubTitle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  margin-top: 2px;

  /*
  background: red;*/
`;
const SearchBox = styled.div`
  margin-left: 9px;
  background: #fefeff;
  border: 1px solid #aab4be;
  box-sizing: border-box;
  border-radius: 8px 5px 8px 8px;
  height: 34px;
  text-align: left;
`;
const PendingIndicator = styled.div`
  height: 32px;
  margin-left: 9px;
  margin-top: 8.5px;
  text-align: left;

  border: 1px solid transparent;
  /*
  background-color: green;*/
`;
const StyledUserInput = styled.input`
  position: absolute;
  display: inline-block;
  width: 224px;
  height: 24px;
  font-style: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  background-color: transparent;
  padding: 0px;
  border-width: 0px;
  &:focus {
    outline: none;
  }
  margin-top: 4px;
  margin-left: 8px;
`;/*
const PersonIndicatorContainer = styled.div`
  position: absolute;
  display: inline-block;
  padding: 3px;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin: 4px;
  
  //background-color: red;
`;*/
const PendingCounts = styled.div`
  position: absolute;
  display: inline-block;
  width: 224px;
  height: 14px;
  font-style: italic;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  border-width: 0px;
  margin-top: 9px;
  margin-left: 8px;
  /*
  background-color: gray;*/
`;
const UserList = styled.div`
  height: 187px;
  margin-left: 9px;
  margin-top: 13px;
  text-align: left;
  overflow: "auto";
`;


class ListsList extends Component {
/*
    componentWillMount() {
        //this.props.getLists();
        this.addList = this.addList.bind(this);
        this.handleCM = this.handleCM.bind(this);
    }*/
    state = {
        isOpen: false,
        keyWord: '',
    }

    addList = (title) => {
        this.props.addList({title: title});
    }

    handleCM = (e, id) => {
        console.log('CMMMCMCMCMMCMCMCMMCMCMC');
        console.log(this.props);
        const {auth} = this.props;
        console.log(id);
        const modals = [
            (id[1].adminUsers[0]===auth.uid)?
                {
                    label: "Manage team",
                    action: () => {
                        this.props.selectList(id);
                        this.setState({ 
                            isOpen: true,
                            keyWord: ''
                        });
                    },
                }:
                null,  
            {
                label: "Delete List",
                action: () => {
                    this.props.deleteList(id)
                },
            }
        ].filter(item=>item!==null);
        this.props.openCM(e, modals);
    }
    
    handleCancel = () => {
        console.log("Cancel function!");
        this.setState({ isOpen: false });
    };
    
    onClick = () => {
        console.log("Submit function!");
        console.log(this.child);
        this.setState({ isOpen: false });
        this.child.addUsersToList();
    };

    handleInput = (event) => {
        const keyWord = event.target.value;
        if(event.target.value.length>2){
            this.setState({
                ...this.state,
                keyWord: keyWord
            })
            console.log("!@");
            console.log(this.context.store)
            
            
        } else if((event.target.value.length<3)&&(this.state.keyWord!=='')){
            console.log('1121212');
            this.setState({
                ...this.state,
                keyWord: ''
            })
        }
    };
    
    /*
    getUserCount = (tasks, userTasks) =>{
        const cmp = tasks.filter((item) => {
            const gs = userTasks.indexOf(item);
            return (gs>-1)? true: false;
        });
        return cmp.length;
    }*/

    render() {
        /*const list = this.props.lists.map((list, i) => {
            return (
                <List activeID={this.props.activeID} id={list.id} key={list.id} label={list.title} icon="list"
                      count={list.count} action={this.props.selectList} contextMenu={this.handleCM}/>
            )
        });
        return (
            <Wrapper>
                <Loader isLoading={this.props.listsLoading}/>
                <ItemCollection>
                    <List activeID={this.props.activeID} label="Inbox" type="LIST_INBOX" icon="inbox" id={0}
                          count={this.props.inboxCount} action={this.props.selectList}/>
                </ItemCollection>
                <Stretch>
                    {/*<ItemCollection>
                        <List label="Assigned to me" type="LIST_ASSIGNED" icon="Personal" id={'a'}/>
                        <List label="Planed" type="LIST_PLANED" icon="Calendar" id={'b'}/>
                        <List label="Locational" type="LIST_LOCATIONAL" icon="Location" id={'c'}/>
                    </ItemCollection>}
                    <ItemCollection separated={true}>
                        {list}
                    </ItemCollection>
                </Stretch>
                <ItemCollection>
                    <AddInput label="Add new list..." action={this.addList}/>
                </ItemCollection>
            </Wrapper>
        )*/
        const { lists, activeID, listUsers, listLoading} = this.props;
        const inbox = lists ? (
            <ItemCollection>
                    <List activeID={activeID} label="Inbox" type="LIST_INBOX" icon="inbox" id={lists[0]}
                          count={lists[0][1].tasks.length} action={this.props.selectList}/> 
                            {/*count={this.props.inbox.tasks.length} action={this.props.selectList}/>*/}
            </ItemCollection>
        )
        : (
            <ItemCollection>
                    <List activeID={0} label="Inbox" type="LIST_INBOX" icon="inbox" id={1}
                          count={this.props.indexCount} action={this.props.selectList}/> 
            </ItemCollection>
        );
        
        const areLists = lists && lists.length;
        const list = areLists ? lists.map((list, i) => {
            if(i!==0 && list[1]){
               return (
                    <List activeID={activeID} id={list} key={list} label={list[1].title} icon="list"
               count={list[1].tasks.length} action={this.props.selectList} contextMenu={this.handleCM}/>
                   )
            }
            else
                return null;
        }).filter((item)=>item!==null)
        : '';
        const l_data = activeID[1]?{
            adminUsers: activeID[1].adminUsers, 
            id: activeID[0]
        }:undefined;
        const l_id = activeID[0]?activeID[0]:undefined;
        const l_users = listUsers?listUsers:[];
        const l_title = !listLoading&&activeID[1]?activeID[1].title:'Loading...';
        console.log(l_users);

       // {adminUsers: activeID[1].adminUsers, id: activeID[0]}}} listid={activeID[0]} listusers={activeID[1].users
        return (
            <Fragment>
                <Wrapper>
                    <Loader isLoading={this.props.listLoadingAlt}/>
                    {inbox}
                    <Stretch>
                        {/*<ItemCollection>
                            <List label="Assigned to me" type="LIST_ASSIGNED" icon="Personal" id={'a'}/>
                            <List label="Planed" type="LIST_PLANED" icon="Calendar" id={'b'}/>
                            <List label="Locational" type="LIST_LOCATIONAL" icon="Location" id={'c'}/>
                        </ItemCollection>*/}
                        <ItemCollection separated={true}>
                            {list} 
                        </ItemCollection>
                    </Stretch>
                    <ItemCollection>
                        <AddInput label="Add new list..." action={this.addList}/>
                    </ItemCollection>
                </Wrapper>
                <Modal
                    title={
                        <>
                        <MainTitle>Manage list team</MainTitle>
                        <SubTitle>#{l_title}</SubTitle>
                        </>
                    }
                    isOpen={this.state.isOpen}
                    onCancel={this.handleCancel}
                    footerCpmponent={        
                        <Button onClick={this.onClick}>
                            {"Apply"}
                        </Button>
                    }
                    >
                    <SearchBox>
                        {/*<PersonIndicatorContainer>*/}
                             <AddPersonIcon/>{/*<Icon icon={user} size={18} />*/}
                        {/*</PersonIndicatorContainer>*/}
                        <StyledUserInput onChange={this.handleInput} />
                    </SearchBox>
                    <PendingIndicator>
                        {/*<PersonIndicatorContainer>*/}
                            <PendingPassive/>{/*<Icon icon={users} size={18} />*/}
                {/*</PersonIndicatorContainer>*/}
                        <PendingCounts>nan Pending</PendingCounts>
                    </PendingIndicator>
                    <UserList>
                        { <ScrollComponentList keywordprop={this.state.keyWord} listinfo={l_data} listid={l_id} listusers={l_users} onRef={ref => (this.child = ref)}/>}
                    </UserList>
                </Modal>
            </Fragment> 
            )
        
    }
}

function mapStateToProps(state) {
    console.log('**********');
    console.log(state);
    const objFields = state.firestore.data.lists;
    var listsTmp = (objFields==null) ? null : Object.entries(objFields);
    const inbox = state.firestore.data.inboxes ? Object.entries(state.firestore.data.inboxes) : null;
    /*if(listsTmp==null)
    {
        listsTmp = new Array();
    }*/
    
    //console.log('-----------------');
    const users = state.firestore.data.users;
    //const authUserTasks = (users)? Object.entries(state.firestore.data.users)[0][1].tasks: null;
    const listUsers = state.firestore.ordered.taskUsers;
    //console.log(authUserTasks);
    if(listsTmp && inbox){
        listsTmp.unshift(inbox[0]);
        //this.props.selectList(listsTmp[0])
    }
    else{
        if(inbox){
            listsTmp = [];
            listsTmp.push(inbox[0]);
        }
    }
    //console.log(listsTmp);
    const lists = listsTmp ? listsTmp : null;
    const listsLoading = inbox/*state.get('firestore').data.lists*/ ? false : true;
    const inboxCount = inbox ? inbox[0][1].tasks.length : null;
    //console.log(inboxCount);
    return {
        auth: state.firebase.auth,
        inboxCount: inboxCount, //inbox.tasks.length,
        lists: lists,
        listLoading: state.lists.listsLoading,
        //userTasks: authUserTasks,
        listUsers: listUsers,
        listLoadingAlt: state.lists.listLoadingAlt,
        listsLoading: listsLoading,//state.getIn(['lists', 'listsLoading']),
        activeID: state.lists.activeID,
        //auth: state.firebase.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //getLists: ListActions.getLists,
        addList: ListActions.addList,
        deleteList: ListActions.deleteList,
        selectList: ListActions.selectList,
        openCM: ContextMenuActions.openContextMenu,
    }, dispatch)
}

//export default connect(mapStateToProps, mapDispatchToProps)(toJS(ListsList));
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
        console.log(props);
        const uid = props.auth.uid;
        const fireQuery = [  
            /*{collection: 'users', doc: uid}   ,  */ 
            { collection: 'inboxes',  where: ['user', '==', uid]},
            { collection: 'lists', where: [
                ['isDeleted', '==', false],
                ['users', 'array-contains', uid]
            ]},           
            (props.activeID[0])?{ 
                collection: 'users', 
                where: [
                ['lists', 'array-contains', props.activeID[0]]
                ],
                storeAs: 'taskUsers'
            }:null,
        ].filter(item=>item!==null);
        return fireQuery
    })
  )(toJS(ListsList)); 