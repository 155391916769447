import {fromJS} from 'immutable';
//import * as firebase from 'firebase/app';
//import 'firebase/firestore';

//All db query need to move on cloud functions
//for subtask need to refactor
export function addTask(task) {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        //const firestore = getFirestore();
        //console.log('firebasssseee');
        //console.log(firebase);
        //const author = getState().get('firebase').auth.uid;
        dispatch({
            type: 'TASK_ADD_PENDING',
            payload: true
        });
        const activeList =  getState().lists.activeID;
        if(!activeList){
            dispatch({
                type: 'TASK_ADD_ERROR',
                payload: false
            })
        }
        else{
            const firebase = getFirebase();
            //firebase.functions().useFunctionsEmulator('http://localhost:5000');
            //firebase.functions('europe-west1');
            var ans = await firebase.functions().httpsCallable('addTask')({
                taskTitle: task.title,
                listID: activeList[0],
                listType: activeList[1].type,
                taskType: task.type
            }).then((data) => {
                //console.log("fncResult");
                return data;
            });
            //console.log("!fncResult");
            //console.log(ans);
            if(!(ans.data === 'ok')){
                dispatch({
                    type: 'TASK_ADD_ERROR',
                    payload: false
                })
            }
            dispatch({
                type: 'TASK_ADD_SUCCESS',
                payload: false,
            });
        }
       
    }
}
/*
function UserTaskUpdate(users, taskId, firestore){
    console.log('sheeet');
    users.forEach((doc) => {
        const userTasksList = doc.tasks;
        var uTaskList = new Array();
        for(var j=0; j<userTasksList.length; j++){
            uTaskList.push(userTasksList[j]);
        }
        const index = uTaskList.indexOf(taskId);
        if(index>-1){
            uTaskList.splice(index, 1);
        }
        console.log('/////////////////');
        console.log(doc);
        console.log(doc.id);
        firestore.collection('users').doc(doc.id).update({tasks: uTaskList}) 
    });
    console.log('-------++++++----');
}*/

export function deleteTask(task) {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const id = task.id;
        //const firestore = getFirestore();
        //const author = getState().get('firebase').auth.uid;
        //var userDocs = [];
        dispatch({
            type: 'TASK_DELETE_PENDING',
            payload: true
        });
        const activeList =  getState().lists.activeID;
        if(!activeList){
            dispatch({
                type: 'TASK_DELETE_ERROR',
                payload: false
            })
        }
        else{
            const firebase = getFirebase();
            //console.log('979797979797797979797797');
            //console.log(firebase.functions());
            //firebase.functions().useFunctionsEmulator('http://localhost:5000');
            var ans = await firebase.functions().httpsCallable('deleteTask')({
                taskID: id,
                listID: activeList[0],
                listType: activeList[1].type
            }).then((data) => {
                //console.log("fncResult");
                return data;
            });
            //console.log("!fncResult");
            //console.log(ans);
            if(!(ans.data === 'ok')){
                dispatch({
                    type: 'TASK_DELETE_ERROR',
                    payload: false
                })
            }
            dispatch({
                type: 'TASK_DELETE_SUCCESS',
                payload: false,
            });
        }
       
    };
}

function taskUpdate(task, data) {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const id = task.id;
        //const firestore = getFirestore();
        const firebase = getFirebase();
        const db = firebase.firestore();
        
        //const firebase = getFirebase();
        dispatch({
            type: 'TASK_UPDATE_PENDING',
            payload: ''
        });
        db.collection('tasks').doc(id).update(data).then(() => {
            dispatch({
                type: 'TASK_UPDATE_SUCCESS',
                payload: {id, data},
            });
        }).catch((err) => {
            dispatch({
                type: 'TASK_UPDATE_ERROR',
                payload: err
            })
        })
        /*firestoredb.update({collection: 'tasks', doc: id}, data).then(() => {
            dispatch({
                type: 'TASK_UPDATE_SUCCESS',
                payload: {id, data},
            });
        }).catch((err) => {
            dispatch({
                type: 'TASK_UPDATE_ERROR',
                payload: err
            })
        })*/
    };
}

export function checkTask(task) {
    return taskUpdate(task, {is_done: !task.is_done});
}

export function renameTask(task) {
    const title = prompt("Enter new title for task:", task.title);
    if (title == null || title === '') {
        return {
            type: 'TASK_RENAME_CANCELED',
            payload: fromJS(task),
        }
    } else {
        return taskUpdate(task, {title: title});
    }
}
export function setResponsiblePersonToTask(task, newLeadID) {
    //console.log('ACTION');
    if (task.lead_id === newLeadID) {
        return {
            type: 'TASK_SETRESPONSIBLE_SAMEUSER',
            payload: fromJS(task),
        }
    } else {
        return taskUpdate(task, {lead_id: newLeadID});
    }
}

export function updateNote(task, note) {
    return taskUpdate(task, {note: note});
}

export function updateDueDate(task, date) {
    return taskUpdate(task, {due_time: date});
}

export function selectTask(task) {
    return {
        type: 'TASK_SELECT',
        payload: task
    }
}

export function moveUp(task) {
    return {
        type: 'TASK_MOVE_UP',
        payload: task
    }
}