import React, {Component} from 'react';
import AddInput from "../common/AddInput";
import Task from "./Task";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {toJS} from "../../to-js";
import ItemCollection from "../items/ItemsCollection";
import * as TasksActions from '../../actions/tasksActions';
import * as ListActions from '../../actions/listsActions';
import * as ContextMenuActions from "../../actions/contextMenuActions";
import Loader from "../common/Loader";
import styled from "styled-components";
import {firestoreConnect} from "react-redux-firebase";
 

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

class TasksList extends Component {
    /*constructor(props) {
        super(props);

        asd
        this.addTask = this.addTask.bind(this);
        this.handleCM = this.handleCM.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.checkboxHandler = this.checkboxHandler.bind(this);
    }*/
/*
    componentWillMount() {
        //this.props.loadTasks();
    }*/

    addTask = (title) => {
        this.props.addTask({
            title: title,
            list_id: this.props.activeListID,
            type: "tasks",
        })
       // ListActions.selectList(this.props.activeListID);
    }
    handleCM = (e, task) => {
        this.props.openCM(e, [
            {
                label: "Delete Task",
                action: () => {
                    this.props.deleteTask(task);
                },
            },
            {
                label: 'Rename...',
                action: () => {
                    this.props.renameTask(task);
                },
            }
        ])
    }

    selectHandler = (task) => {
        this.props.selectTask(task);
    }

    checkboxHandler = (task) => {
        this.props.checkTask(task);
    }

    render() {
        const isLoading = (!this.props.tasksLoading && !this.props.tasksLoadingAlt)? false : true;
        const areTasks = this.props.tasks && this.props.tasks.length;
        //console.log(this.props.tasksLoadingAlt);
        const list = areTasks ? this.props.tasks.map((task, i) => {
            return (
                <Task task={task} active={task.id === this.props.activeTaskID} done={task.is_done} key={i}
                      contextMenu={this.handleCM} selectHandler={this.selectHandler}
                      checkboxHandler={this.checkboxHandler}/>
            )
        }) : '';
        return (
            <Wrapper>
                <Loader isLoading={this.props.tasksLoadingAlt}/>
                <ItemCollection>
                    <AddInput label="Add new task..." action={this.addTask}/>
                </ItemCollection>
                <ItemCollection separated={true}>
                    {list}
                </ItemCollection>
            </Wrapper>
        )
    } 
}

function mapStateToProps(state) {
    //const loadTasks = (activeListID != state.get('lists').activeID) ? false : true;
    const activeListID = state.lists.activeID;
    const activeTaskID = state.tasks.activeID.toString();
    const tasks = state.firestore.ordered.tasks;
    var loadTasks = (tasks ) ? false : true;
    //console.log( state.tasks.tasksLoadingAlt);
    return {
        /*tasks: state.getIn(['tasks', 'tasks']).filter(function (task, i) {
            return task.get('list_id') === activeListID && !!task.get('root');
        }),*/
        tasks: tasks,
        tasksLoading: loadTasks,
        tasksLoadingAlt: state.tasks.tasksLoadingAlt,
        activeListID: activeListID,
        activeTaskID: activeTaskID,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addTask: TasksActions.addTask,
        deleteTask: TasksActions.deleteTask,
        selectTask: TasksActions.selectTask,
        checkTask: TasksActions.checkTask,
        renameTask: TasksActions.renameTask,
        openCM: ContextMenuActions.openContextMenu, 
    }, dispatch)
} 



export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
        if(props.activeListID[1]){
           // console.log(props.activeListID[1].type);
            //console.log(props.activeListID[0]);
            const uid = state.firebase.auth().O;
            //console.log(uid);
            return[
                {
                    collection: 'tasks', 
                    where: [
                        ['main_list_id', '==', props.activeListID[0]],
                        ['isDeleted', '==', false],
                        ['type', '==', 'tasks'],
                        /*['users', 'array-contains', uid]*/
                    ]}//, doc: props.activeListID[1].tasks}
            ]
        }
        else{
            return[
                {collection: 'tasks', where: ['main_list_id', '==', 'empty']}//, doc: props.activeListID[1].tasks}
            ]
        }
    })
)(TasksList);