import {Map} from 'immutable';

const defState = Map({
    weekShift: 0,
    now: new Date(),
    ppm: 8/5, //pixels per minute
    mts: 5, //minimal time step
    grid: {
        scrollTop: 0,
    }
});

export default function reducer(state = defState, action) {
    switch (action.type) {
        case "SHIFT_WEEK": {
            const {shift} = action.payload;
            return state.set('weekShift', shift);
        }
        default:
            return state;
    }
};