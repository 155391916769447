import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as LoginActions from '../../actions/loginActions';
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import {Redirect} from "react-router-dom";
import styled from 'styled-components'
import Loader from "../common/Loader";
import { createGlobalStyle } from 'styled-components'
import { BeatLoader } from 'react-spinners';

const Page = styled.div`
      width: 300px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 50px;
      padding: 20px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-radius: 5px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `;
    const CalendaysLogo = styled.div`
      text-align: center;
      max-width: 100%;
      margin-bottom: 30px;
    `;
    const ButtonContainer = styled.div`
      justify-content: center;
      display: flex;
      box-pack: center;
    `;
    const GoogleSignButton = styled.button`
      display: inline-block;
      background: rgb(209, 72, 54);
      color: rgb(255, 255, 255);
      width: 190px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const BackHome = styled.a`
      display: block;
      text-align: center;
      margin-top: 30px;
      color: rgb(0, 137, 209);
      text-decoration: underline;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const GlobalStyle = createGlobalStyle`
      body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        background: url('/img/bg.png') no-repeat;
        background-size: auto;
        background-position: center;
        background-color: #274246;
        min-height: 100%;
      }
    `;

class Logout extends Component {
  render () {
      const {logout, loggedOut} = this.props;
      //console.log(loggedIn);
      //console.log(this.state.loading);
      if (!loggedOut){
        logout();
        //console.log('!!!!!!!!!!!!!!!!!@@#KJSFJLK!!!!');
        //return await this.handleLogout();
      } 
      else{
        return <Redirect to='/login'/>;
      }
      return (
        <Page >
          <GlobalStyle/>
            <CalendaysLogo>
              <img src="/img/login-logo.svg" alt='calendays'/>
            </CalendaysLogo>
            <ButtonContainer>
            <BeatLoader
                  color={'#AAB4BE;'}/>
            </ButtonContainer>
              <BackHome href='http://www.calendays.com/'>Back home</BackHome> 
            </Page>
      )
    }
}

function mapStateToProps(state) {
  console.log(state);
  //const {allIds} = state.firestore.listeners;
  return {
      loggedOut: state.authreducer.loggedOut,
      //listeners: allIds,
      loggedIn: state.authreducer.loggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      logout: LoginActions.logout,
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(toJS(Logout));

