import React, {Component} from 'react';
import styled from 'styled-components';
import moment from "moment";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../to-js";
import TopRow from "./calendar/TopRow";
import CalendarGrid from "./calendar/CalendarGrid";
import * as TasksActions from "../actions/tasksActions";

class Calendar extends Component {

    /*componentWillMount() {
       // this.props.loadTasks();
    }*/

    render() {
        return (
            <div className={this.props.className}>
                <TopRow/>
                <CalendarGrid mainUser={this.props.mainUser}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const mainUser = state.firestore.ordered.mainUser[0];
    return {
        mainUser: mainUser
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(Calendar)`
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`));