import React, {Component} from 'react';
import styled from 'styled-components';
import Item from "../items/Item";
import ItemIcon from "../items/ItemIcon";
import ItemLabel from "../items/ItemLabel";
import {mixins} from "../../mixins";

const CheckboxContainer = styled.div`
  height: ${p => !p.subtask ? '100%' : '40px'};
  position: relative;
  width: 100%;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckboxBox = styled.div`
    height: 18px;
    width: ${p => p.checked ? 8 : 18}px;
    border-radius: ${p => p.checked ? 0 : 2}px;
    border: 1px solid ${p => p.theme.cLight};
    border-top-color: ${p => p.checked ? 'transparent' : p.theme.cLight};
    border-left-color: ${p => p.checked ? 'transparent' : p.theme.cLight};
    box-sizing: border-box;
    ${mixins.ease()};
    transform: ${p => p.checked ? `translate(0, -2px) rotate(45deg)` : `none`};
`;

class Task extends Component {
    constructor(props) {
        super(props);

        this.handleCM = this.handleCM.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    handleCM(e) {
        if (typeof (this.props.contextMenu) === 'function') {
            this.props.contextMenu(e, this.props.task);
        }
    }

    handleSelect() {
        if (typeof (this.props.selectHandler) === 'function') {
            this.props.selectHandler(this.props.task.id);
        }
    }

    handleCheckbox() {
        if (typeof (this.props.checkboxHandler) === 'function') {
            this.props.checkboxHandler(this.props.task);
        }
    }

    render() {
        return (
            <div onContextMenu={this.handleCM}>
                <Item active={this.props.active} header={this.props.header} autoheight={this.props.subtask}>
                    <ItemIcon>
                        <CheckboxContainer subtask={this.props.subtask} onClick={this.handleCheckbox}>
                            <CheckboxBox checked={!!this.props.task.is_done}/>
                        </CheckboxContainer>
                    </ItemIcon >
                    <ItemLabel checked={!!this.props.task.is_done} onClick={this.handleSelect}>
                        {this.props.task.title}
                    </ItemLabel>
                </Item>
            </div>
        )
    }
}

export default Task;