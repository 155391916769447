const initState = {
  authError: null
}

const authReducer = (state = initState, action) => {
  const {type} = action;
  switch(type){
    case 'ON_INIT':
      return{
        ...state,
        readyToInit: false
      }
    case 'ON_INIT_END':
      return{
        ...state,
        readyToInit: true
      }
   /*case 'LOGIN_ERROR':
      console.log('auth error');
      return {
        ...state,
        authError: 'Login failed'
      }
    case 'LOGIN_SUCCESS':
      console.log('login success');
      return {
        ...state,
        authError: null
      }*/
      case 'GOOGLE_SIGN_PENDING':
          return {
            ...state,
            inProgress: true,
            authError: null,
            loggedIn: false
          }
    case 'SIGNOUT_SUCCESS':
      console.log('signout');
      return{
        loggedIn: false,
        loggedOut: true
      }
      case 'SIGNOUT_PENDING':
        return{
          ...state
        }
      //console.log('signout success')
    /*case 'SIGNUP_SUCCESS':
      console.log('up success')
      return{
        ...state,
        authError: null
      }
    case 'SIGNUP_ERROR':
      console.log('signup error')
      return{
        ...state,
        authError: action.err.message
      }*/
      
    case 'GOOGLE_SIGN_SUCCESS':
      //console.log('google up success')
      return{
        ...state,
        authError: null,
        inProgress: false,
        loggedIn: true,
        loggedOut: false
      }
    case 'GOOGLE_SIGN_ERROR':
      //console.log('google up error')
      return{
        ...state,
        authError: action.err,
        inProgress: false,
        loggedIn: false,
        loggedOut: false
      }
    default:
      return state
  }
};

export default authReducer;