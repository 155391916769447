import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as calendarActions from "../../actions/calendarActions";
import moment from "moment";
import CardBody from "./CardBody";


const Card = styled.div`
  position: absolute;
  height: 32px;
  //top: ${p => p.startOffset}px;
  width: calc(1 / 7 * (100% - 52px));
  padding-left: 2px;
  padding-right: 1px;
  box-sizing: border-box;
  &.dragging{
    opacity: 0.5;
    //transform: rotate(10deg);
    user-select: none;
  }
`;

const Ghost = styled.div`
  position: absolute;
  height: 32px;
  display: ${p => p.visible ? 'block' : 'none'};
  width: calc(1 / 7 * (100% - 52px));
  padding-left: 2px;
  padding-right: 1px;
  box-sizing: border-box;
  border-radius: ${p => p.theme.sCardRadius}px;
  border: 2px solid ${p => p.theme.cEventRed};
`;

class TaskCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            start: moment(this.props.task.due_time),
            dragging: false,
            initialX: 0,
            initialY: 0,
            xOffset: 0,
            yOffset: 0,
            currentX: 0,
            currentY: 0,
            initialGridScroll: this.props.getGridScroll(),
            gridScroll: this.props.getGridScroll(),
            ghostTop: 0,
        };
    }

    dragStart = (e) => {
        if (e.type === "touchstart") {
            this.setState({
                initialX: e.touches[0].clientX - this.state.xOffset,
                initialY: e.touches[0].clientY - this.state.yOffset,
            });
        } else {
            this.setState({
                initialX: e.clientX - this.state.xOffset,
                initialY: e.clientY - this.state.yOffset,
            });
        }
        this.setState({
            dragging: true,
        });
        this.props.gridListener(this.handleGridScroll);
        document.body.addEventListener('touchmove', this.drag);
        document.body.addEventListener('mousemove', this.drag);
        document.body.addEventListener('touchend', this.dragEnd);
        document.body.addEventListener('mouseup', this.dragEnd);
    };

    handleGridScroll = (e) => {
        const scrollDelta = e.target.scrollTop - this.state.gridScroll;
        this.setState({
            gridScroll: this.state.gridScroll + scrollDelta,
            currentY: this.state.currentY + scrollDelta,
        });
    };

    drag = (e) => {
        if (this.state.dragging) {
            e.preventDefault();
            if (e.type === "touchmove") {
                this.setState({
                    currentX: e.touches[0].clientX - this.state.initialX,
                    currentY: e.touches[0].clientY - this.state.initialY - (this.state.initialGridScroll - this.state.gridScroll),
                });
            } else {
                this.setState({
                    currentX: e.clientX - this.state.initialX,
                    currentY: e.clientY - this.state.initialY - (this.state.initialGridScroll - this.state.gridScroll),
                });
            }
            this.setState({
                xOffset: e.clientX - this.state.currentX,
                yOffset: e.clientY - this.state.currentY,
            });
            //setTranslate(currentX, currentY, dragItem);
        }
    };

    dragEnd = (e) => {

        const task = this.props.task;
        const ppm = this.props.ppm;
        const mts = this.props.mts;
        const start = this.state.start;
        const startTimeM = Math.round((start.hours() * 60 + start.minutes())/mts) * mts;
        const timeM = Math.round((this.state.currentY/ppm)/mts)*mts + startTimeM;

        const now = moment(this.props.globalNow);
        const startOfWeek = now.clone().add(this.props.weekShift, 'weeks').startOf('week');

        const pureGridWidth = this.props.getGridWidth() - 52;
        const days = Math.round(this.state.currentX/(pureGridWidth/7));

        //console.log(startOfWeek.add(days, 'days').add(timeM, 'minutes').format('DD, HH:mm'));
        //console.log(timeM);
        this.setState({
            start: startOfWeek.add(days + start.weekday(), 'days').add(timeM, 'minutes'),
        });

        this.props.gridListener(this.handleGridScroll, false);
        document.body.removeEventListener('touchmove', this.drag);
        document.body.removeEventListener('mousemove', this.drag);
        document.body.removeEventListener('touchend', this.dragEnd);
        document.body.removeEventListener('mouseup', this.dragEnd);

        this.setState({
            initialX: 0,//this.state.currentX,
            initialY: 0,//this.state.currentY,
            currentX: 0,
            currentY: 0,
            xOffset: 0,
            yOffset: 0,
            initialGridScroll: this.props.getGridScroll(),
            gridScroll: this.props.getGridScroll(),
            dragging: false,
        });
    };

    render() {
        const task = this.props.task;
        const ppm = this.props.ppm;
        const mts = this.props.mts;
        const start = this.state.start;
        //console.log(start.format('DD, HH:mm'));
        const startOffset = start.hours() * 60 * ppm + (Math.round(start.minutes()/mts)*mts) * ppm;
        const leftOffset = `calc((${start.weekday()}/7*(100% - 52px)) + 52px)`;
        const ghostTop = ((Math.round((this.state.currentY/ppm)/mts)*mts)*ppm) + startOffset;
        const pureGridWidth = this.props.getGridWidth() - 52;
        const ghostLeft = `calc((${Math.round(this.state.currentX/(pureGridWidth/7)) + start.weekday()}/7*(100% - 52px)) + 52px)`;
        return (
            <React.Fragment>
                <Card
                    onTouchStart={(e) => this.dragStart(e)}
                    onMouseDown={(e) => this.dragStart(e)}
                    style={{
                        transform: `translate3d(${this.state.currentX}px, ${this.state.currentY}px, 0)`,
                        top: startOffset,
                        left: leftOffset,
                    }}
                    className={`${this.state.dragging ? 'dragging' : ''}`}>
                    <CardBody task={{...task, due_time: start}}/>
                </Card>
                <Ghost visible={this.state.dragging} style={{
                    top: ghostTop,
                    left: ghostLeft,
                }}/>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {/*
        ppm: state.getIn(['calendar', 'ppm']),
        mts: state.getIn(['calendar', 'mts']),
        globalNow: state.getIn(['calendar', 'now']),
        weekShift: state.getIn(['calendar', 'weekShift']),*/
        
        ppm: state.calendar.get('ppm'),
        mts: state.calendar.get('mts'),
        globalNow: state.calendar.get('now'),
        weekShift: state.calendar.get('weekShift'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(TaskCard)`
`));