import React, {Component} from 'react';
import styled from 'styled-components';
import TaskInfoCard from "./tasks/TaskInfoCard";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {toJS} from "../to-js";
import * as TasksActions from "../actions/tasksActions";
import * as ContextMenuActions from "../actions/contextMenuActions";
import * as DatepickerActions from '../actions/datepickerActions';
import SubtasksCard from "./tasks/SubtasksCard";
import {firestoreConnect} from "react-redux-firebase";

const Wrapper = styled.div`
    
`;

class TaskView extends Component {


    constructor(props) {
        super(props);

        this.handleNote = this.handleNote.bind(this);
        this.addSubTask = this.addSubTask.bind(this);
        this.handleDatepicker = this.handleDatepicker.bind(this);
    }

    handleNote(note) {
        this.props.updateNote(this.props.activeTask, note);
    }

    addSubTask(title) {
        this.props.addTask({
            title: title,
            list_id: this.props.activeListID,
            parent_id: this.props.activeTaskID,
            root: 0,
        });
    }

    handleDatepicker(e, task){
        this.props.openDatepicker(e, (stamp) => this.props.updateDueDate(task, stamp));
    }

    render() {
        if (this.props.activeTask) {
            return (
                <Wrapper>
                    <TaskInfoCard task={this.props.activeTask} active_list={this.props.activeListID[0]} openDatepicker={this.handleDatepicker}/>
                    <SubtasksCard subtasks={this.props.subtasks} handleNote={this.handleNote}
                                  addSubTask={this.addSubTask} task={this.props.activeTask}
                                  activeTaskID={this.props.activeTaskID}
                                  checkTask={this.props.checkTask} openCM={this.props.openCM}
                                  deleteTask={this.props.deleteTask} selectTask={this.props.selectTask} moveUp={this.props.moveUp}/>
                </Wrapper>
            )
        }
        else {
            return (
                ''
            );
        }
    }
}

function mapStateToProps(state) {
    console.log(state);
    const activeListID = state.lists.activeID;//state.getIn(['lists', 'activeID']);
    const activeTaskID = state.tasks.activeID;
    const tasks = state.firestore.ordered.tasks;
    return {
        activeTask: tasks ? tasks.find(task => task.id === activeTaskID) : false,
        subtasks: tasks ? state.firestore.ordered.tasks.filter(function (task, i) {
            return task.parent_id === activeTaskID.toString();
        }) : [],
        activeListID: activeListID,
        activeTaskID: activeTaskID,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateNote: TasksActions.updateNote,
        updateDueDate: TasksActions.updateDueDate,
        addTask: TasksActions.addTask,
        deleteTask: TasksActions.deleteTask,
        selectTask: TasksActions.selectTask,
        checkTask: TasksActions.checkTask,
        moveUp: TasksActions.moveUp,
        openCM: ContextMenuActions.openContextMenu,
        openDatepicker: DatepickerActions.openDatepicker,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    /*firestoreConnect([
        {collection: 'tasks'}
    ])*/
)(TaskView);