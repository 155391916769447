import React, {Component} from 'react';
import styled from 'styled-components'
import ListsList from './lists/ListsList'
import TasksList from "./tasks/TasksList";
import TaskView from "./TaskView";
import {connect} from "react-redux";
import { compose } from 'redux'
 
const Window = styled.div`
  //height: 100%;
  position: relative;
  background-image: url(${props=> props.backgroundUrl!=="" ? props.backgroundUrl : "/img/bg_stars.jpg"});
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  &__bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const HomeColumn = styled.div`
  flex-basis: ${p => p.theme.sListsWidth}px;
  padding: ${p => p.theme.gutter}px;
`;

const TasksColumn = styled.div`
  flex-grow: 1;
  padding: ${p => p.theme.gutter}px;
`;

const TaskColumn = styled.div`
  width: ${p => p.theme.sTaskWidth}px;
  padding: ${p => p.theme.gutter}px;
`;

class App extends Component {
    render() {
      const {backGroundPicUrl} = this.props.mainUser;
        return (
            <Window backgroundUrl={backGroundPicUrl}>
                <HomeColumn>
                    <ListsList/>
                </HomeColumn>
                <TasksColumn> 
                    <TasksList/>
                </TasksColumn>
                <TaskColumn>
                    <TaskView/>
                </TaskColumn>
            </Window>
        );
    }
}

function mapStateToProps(state) {
  console.log(state);
  return {
      mainUser: state.firestore.ordered.mainUser[0]
  };
}
export default compose(
  connect(mapStateToProps))(App);
//export default App;

