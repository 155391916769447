import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../../actions/calendarActions";
import moment from "moment";

const TopRow = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

const Time = styled.div`
  
`;

class CardBody extends Component {
    render() {
        const task = this.props.task;
        const start = moment(task.due_time);
        return (
            <div className={this.props.className}>
                <TopRow>
                    <Time>{start.format('HH:mm')}</Time>
                </TopRow>
                {this.props.task.title}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
       /* ppm: state.getIn(['calendar', 'ppm']),
        mts: state.getIn(['calendar', 'mts']),
        globalNow: state.getIn(['calendar', 'now']),
        weekShift: state.getIn(['calendar', 'weekShift']),*/
        
        ppm: state.calendar.get('ppm'),
        mts: state.calendar.get('mts'),
        globalNow: state.calendar.get('now'),
        weekShift: state.calendar.get('weekShift'),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(CardBody)`
  background-color: ${p => p.theme.cEventRed};
  height: 100%;
  border-radius: ${p => p.theme.sCardRadius}px;
`));