import React, {Component} from 'react';
import styled from 'styled-components';
import AddInput from "../common/AddInput";
import ItemIcon from "../items/ItemIcon";
import TextareaAutosize from 'react-textarea-autosize';
import Task from "./Task";
import ItemCollection from "../items/ItemsCollection";

const Wrapper = styled.div`
  border-radius: ${p => p.theme.borderRadius}px;
  overflow: hidden;
  margin-bottom: ${p => p.theme.gutter}px;   
`;

const NoteContainer = styled.div`
  background-color: ${p => p.theme.cPaper};
  min-height: ${p => p.theme.itemHeight}px;
  display: flex;
`;

const AddContainer = styled.div`
  background-color: ${p => p.theme.cBg};
  position: relative;
  padding-bottom: 1px;
  &:after{
    display: block;
    content: '';
    left: 8px;
    right: 8px;
    width: auto;
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: ${p => p.theme.cLight};
  }
`;

const Textarea = styled.div`
  textarea{
    background: none;
    font-family: Roboto, sans-serif;
    border: 0;
    width: 100%;
    resize: none;
    outline: none !important;
    padding: 10px 10px 10px 0;
  }
  flex-grow: 1;
  align-self: center;
`;

class SubtasksCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.task.note ? this.props.task.note : '',
        };

        this.handleNote = this.handleNote.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkboxHandler = this.checkboxHandler.bind(this);
        this.handleCM = this.handleCM.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
    }

    /*componentWillReceiveProps*/
    componentDidUpdate(prevProps) {
        if(prevProps.task !== this.props.task)
            this.setState({value: this.props.task.note ? this.props.task.note : ''});
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleNote(event) {
        const note = event.target.value;
        if (note !== null && typeof (this.props.handleNote) === 'function' && note !== this.props.task.note) {
            this.props.handleNote(note);
        }
    }

    handleCM(e, task) {
        this.props.openCM(e, [
            {
                label: "Delete Task",
                action: () => {
                    this.props.deleteTask(task)
                },
            },
            {
                separator: true,
            },
            {
                label: 'Move Up',
                action: () => {
                    this.props.moveUp(task.id);
                },
            },
            {
                label: 'Move to list root',
                action: () => {
                },
            }
        ])
    }

    selectHandler(task) {
        this.props.selectTask(task);
    }

    checkboxHandler(task) {
        this.props.checkTask(task);
    }

    render() {
        if (this.props.task) {
            const list = this.props.subtasks.map((task, i) => {
                return (
                    <Task subtask task={task} active={task.id === this.props.activeTaskID} done={task.is_done} key={i}
                          contextMenu={this.handleCM} selectHandler={this.selectHandler} checkboxHandler={this.checkboxHandler}/>
                )
            });
            return (
                <Wrapper>
                    {list.length ?
                        <ItemCollection subtasks>
                            {list}
                        </ItemCollection>
                    : ''}
                    <AddContainer>
                        <AddInput label="Add new subtasktask..." action={this.props.addSubTask}/>
                    </AddContainer>
                    <NoteContainer>
                        <ItemIcon/>
                        <Textarea>
                        <TextareaAutosize rows='1' ref='noteArea' onBlur={this.handleNote} onChange={this.handleChange}
                                          value={this.state.value} placeholder={'Take a note'}/>
                        </Textarea>
                    </NoteContainer>
                </Wrapper>
            )
        }
        else {
            return '';
        }
    }
}

export default SubtasksCard;