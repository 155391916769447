const defState = {
    accounts: [],
};


export default function reducer(state = defState, action) {

    switch (action.type) {
        case "USERSTOTASK_ADD_PENDING": {
            return {...state};
        }
        case "USERSTOTASK_ADD_SUCCESS": {
           /* const task = action.payload;
            return {...state, tasks: {}}*/
            //return state.update('tasks', tasks => tasks.unshift(Map(task)));
            return {...state};
        }
        case "USERSTOTASK_ADD_ERROR": {
            console.log(action.payload);
            return {...state};
        }
        case "USERSTOTASK_ADD_CANCEL": {
            console.log(action.payload);
            return {...state};
        }
        default:
            return state;
    }
};