import { actionTypes } from 'redux-firestore'
//import { useFirebase } from 'react-redux-firebase';
//import * as firebase from 'firebase/app';
//import { getFirebase } from 'react-redux-firebase';
 //all fnc in transactions! 
export function init() {
    return async (dispatch) => {
        dispatch({type: 'ON_INIT'});
        await timeout(2000);
        dispatch({type: 'ON_INIT_END'});
    }
}

export function login(token) {
    return {
        type: 'LOGIN',
        payload: token,
    };
}
const createKeywords = (name) => {
    const arrName = [];
    let curName = '';
    name.split('').forEach((letter, i) => {
        curName += letter;
        if(i>1)
            arrName.push(curName);
    });
    return arrName;
}

const generateKeywords = (words) => {
    const [email, firstName, lastName] = words;
    const nameKeywordFirst = createKeywords(`${firstName} ${lastName}`);
    const nameKeywordLast = createKeywords(`${lastName} ${firstName}`);
    const emailKeyWords = createKeywords(`${email}`);
    return[
        ...new Set([
            ...nameKeywordFirst,
            ...nameKeywordLast,
            ...emailKeyWords
        ])
    ]
}

export function googleLogin() {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        //console.log('&!');
        dispatch({
            type: 'GOOGLE_SIGN_PENDING',
            payload: true
        });
        //const firebase = useFirebase();
        //const firestore = getFirestore();
        //console.log('fbfbfbfbfbfbfbfbfbfbfbfbfbfbfbfbfbfb')
        const firebase = getFirebase();
        const gAuthProvider = new firebase.auth.GoogleAuthProvider();
        return await firebase.auth().signInWithPopup(gAuthProvider)
        .then(async(resp) => {
            //console.log('resp'+resp)
            //firebase.functions().useFunctionsEmulator('http://localhost:5000');
            let ans = await firebase.functions().httpsCallable('login')({
                page: 1,
                limit: 15,
            }).then((data) => {
                //console.log("fncResult");
                return data;
            });
            //console.log("!fncResult");
            //console.log(ans);
            return ans;
            ///////////////////////////
            /*
            const usersRef = firestore.collection('users').doc(resp.user.uid);
            usersRef.get()
            .then((docSnapshot) => {
                if(docSnapshot.exists){
                    //if(!(docSnapshot.data().keywordNickname)){
                    //    console.log("CREATING NICKNAME");
                    //}
                    var personKeywords = docSnapshot.data().personKeywords;
                    if(!(personKeywords)){
                        console.log("CREATING KEYWORDS");
                        const [nameLast, nameFirst] = docSnapshot.data().name.split(' ');
                        personKeywords = generateKeywords([docSnapshot.data().email, nameFirst, nameLast]);
                        console.log(personKeywords);
                    }
                    return firestore.collection('users').doc(resp.user.uid).set({
                        personKeywords: personKeywords,
                        email: docSnapshot.data().email,
                        name: docSnapshot.data().name,
                        photoURL: docSnapshot.data().photoURL,
                        lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
                        regAt: docSnapshot.data().regAt,
                        backGroundPicUrl: docSnapshot.data().backGroundPicUrl,
                        lists: docSnapshot.data().lists,
                        tasks: docSnapshot.data().tasks,
                    })
                }
                else{
                   // console.log('new doc');
                   const [nameLast, nameFirst] = resp.user.displayName.split(' ');
                   const personKeywords = generateKeywords([resp.user.email, nameFirst, nameLast]);
                   firestore.collection('users').doc(resp.user.uid).set({
                        personKeywords: personKeywords,
                        email: resp.user.email,
                        name: resp.user.displayName,
                        photoURL: resp.user.photoURL,
                        lastLogin: firebase.firestore.FieldValue.serverTimestamp(),
                        regAt: firebase.firestore.FieldValue.serverTimestamp(),
                        backGroundPicUrl: '',
                        lists: [],
                        tasks: [],
                    })
                    return firestore.collection('inboxes').doc(resp.user.uid).set({
                        tasks: [],
                        user: docSnapshot.id,
                        type: "inboxes"
                    })
                }
            })
            .catch(err =>{
                firebase.auth().signOut().then(function() {
                    // Sign-out successful.
                  }).catch(function(error) {
                    // An error happened.
                  });
            })     
            */
            ///////////////////////////
        }).then(async (ans) => {
            if(!(ans.data === 'ok')){
                dispatch({ type: actionTypes.CLEAR_DATA })
                const firebase = getFirebase();
                await firebase.logout().then(async()=>{
                    dispatch({ type: actionTypes.CLEAR_DATA });
                    
                });
                dispatch({type: 'GOOGLE_SIGN_ERROR', err: ans.data})
            }
            else{
                dispatch({type: 'GOOGLE_SIGN_SUCCESS'})
            }
        }).catch(err => {
            //console.log('that an error, lol');
            dispatch({type: 'GOOGLE_SIGN_ERROR', err})
        })
    }
    
}
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export const logout = () => {
    return async (dispatch, getState, {getFirebase}) => {
        
        dispatch({ type: actionTypes.CLEAR_DATA })
        dispatch({type: 'SIGNOUT_PENDING'});
        //await timeout(5000);
        //const firebase = getFirebase();
        const firebase = getFirebase();
        await firebase./*auth().signOut()*/logout().then(async()=>{
            dispatch({ type: actionTypes.CLEAR_DATA });
            
        });
        dispatch({type: 'SIGNOUT_SUCCESS'});
        
    }
}
/*
export function logout() {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        firebase.auth().signOut();
    }
}*/