import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as LoginActions from '../../actions/loginActions';
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import { Link } from "react-router-dom";
import PersonCircle from '../people/PersonCircle';

class Login extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {logout, firebase}= this.props;
        return (
            <Link to='/logout'>
                <PersonCircle 
                selectHandler={(props) => {
                    /*firebase.unsetListener({ collection: 'inboxes' });
                    firebase.unsetListener({ collection: 'lists' });
                    firebase.unsetListener({ collection: 'mainUser' });
                    firebase.unsetListener({ collection: 'tasks' });*/
                    //logout();
                }}
                isActive={false} 
                userImg={this.props.mainUser.photoURL} 
                userID={null}
            />
            </Link>
        )
    }
}

function mapStateToProps(state) {
    const users = state.firestore.ordered.mainUser;
    const user = users ? users[0] : null;
    return {
        mainUser: user,
        loggedIn: state.authreducer.loggedIn,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: LoginActions.logout,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Login));

