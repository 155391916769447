import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

import styled from 'styled-components'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {toJS} from "../to-js";

import Layout from "./Layout";
import Login from "./login/Login";
import Invite from "./invites/InviteProceed"

import * as LoginActions from '../actions/loginActions';
import Logout from './login/Logout';

class App extends Component {
    constructor(props) {
        super(props);
        this.props.init();
    }

    render(history) {
        //const layoutElement = this.props.uidExists?<Layout main_user_uid={this.props.userUid}/>:<span>nodata</span>;
        const altrende = ()=>{
            return this.props.uidExists?
            <Layout main_user_uid={this.props.userUid}/>
            :<Login/>;
        } 
        
        return (
            <Router history={history}> 
                <Switch>
                    <Route  exact path={'/login'} component={Login}/>
                    <Route  exact path={'/logout'} component={Logout}/>
                    {<Route exact path={'/invite/:id/:action'} component={Invite}/>}
                    {<Route path={'/'} render={() => { 
                        if(this.props.readyToInit&&this.props.uidExists)
                            return <Layout main_user_uid={this.props.userUid}/>
                        else if(this.props.readyToInit&&!this.props.uidExists)
                            return <Login/>
                        else
                            return <span> </span>;
                    }}/>}
                </Switch>
            </Router>
        )
    }
}

function mapStateToProps(state) {
    const uidExists = state.firebase.auth.uid? true: false;
    //console.log("APPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
    //console.log(state);
    return {
        uidExists,
        readyToInit: state.authreducer.readyToInit,
        userUid: state.firebase.auth.uid
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        init: LoginActions.init,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(App)`
`));
