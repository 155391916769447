/*import * as firebase from 'firebase';
import { bool } from 'prop-types';*/
//import * as firebase from 'firebase/app';
export function addUsersToTask(props) {
    //WORST CODE EVER!!!
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({
            type: 'USERSTOTASK_ADD_PENDING',
            payload: true
        });
        //const firestore = getFirestore();
        const firebase = getFirebase();
        console.log('Props!!!!11');
        console.log(props);
        
        //firebase.functions().useFunctionsEmulator('http://localhost:5000');
        var ans = await firebase.functions().httpsCallable('applyTaskUserManagmentSettings')({
            ...props
        }).then((data) => {
           // console.log("fncResult"); 
            return data;
        });
   //const ans = 'ok';
       /* var ans = await firebase.functions().httpsCallable('sendInvite')({
            objectType: props.task_info.type,
            objectID: props.task_info.id,
            usersID: props.users.map((elm)=>{
                return elm.id;
            }),
        }).then((data) => {
           // console.log("fncResult");
            return data;
        });*/

        //console.log("!fncResult");
        console.log(ans);
        if(!(ans.data === 'ok')){
            dispatch({
                type: 'USERSTOTASK_ADD_ERROR',
                payload: false
            })
        }
        else{
            dispatch({
                type: 'USERSTOTASK_ADD_SUCCESS',
                payload: false,
            });
        }
        //check in tsak
        //e  cancel all
        //add to tasks
        //check in !lists
        //e  add to lists
        //add in tasks
        /*
        for(var i=0; i<props.users.length; i++){
            const userLoacal = props.users[i];
            if(!props.task_info.users.includes(userLoacal.id)){
                firestore.get({collection: props.task_info.main_list_type, doc: props.task_info.main_list_id})
                .then((listSnapshot) => {
                    const list = listSnapshot.data();
                    console.log(list)
                    console.log(userLoacal)
                    if(!list.users.includes(userLoacal.id)){
                        var cancelVar = false;
                        if (window.confirm("List \"" + list.title + "\" don't includes \"" + userLoacal.name + "\" user.\nAdd this user in list?")) {
                            //add this user to list & list to user
                            console.log("You pressed OK!");
                            var listUsersList = new Array();
                            for(var j=0; j<list.users.length; j++){
                                listUsersList.push(list.users[j]);
                            }
                            listUsersList.push(userLoacal.id);
                            firestore.update({
                                collection: props.task_info.main_list_type, 
                                doc: props.task_info.main_list_id}, {
                                    users: listUsersList
                                })

                            var taskUsersList = new Array();
                            for(var j=0; j<props.task_info.users.length; j++){
                                taskUsersList.push(list.users[j]);
                            }
                            taskUsersList.push(userLoacal.id);
                                firestore.update({
                                    collection: props.task_info.type, 
                                    doc: props.task_info.id}, {
                                        users: taskUsersList
                                    })


                            firestore.get({collection: 'users', doc: userLoacal.id})
                            .then((userSnapshot) => {
                                var userLists = new Array();
                                var userTasks = new Array();
                                const userListsList = userSnapshot.data().lists; 
                                const userTaskList = userSnapshot.data().tasks; 
                                for(var j=0; j<userListsList.length; j++){
                                    userLists.push(userListsList[j]);
                                }
                                userLists.push(listSnapshot.id);
                                for(var j=0; j<userTaskList.length; j++){
                                    userTasks.push(userTaskList[j]);
                                }
                                userTasks.push(props.task_info.id);
                                firestore.update({collection: 'users', doc: userLoacal.id}, {lists: userLists, tasks: userTasks})
                            })
                        } else { 
                            cancelVar = true;
                            dispatch({
                                type: 'USERSTOTASK_ADD_CANCEL',
                                payload: false
                            });
                        }
                    }else{
                        firestore.get({collection: 'users', doc: userLoacal.id})
                        .then((userSnapshot) => {
                            var userTasks = new Array();
                            const userTaskList = userSnapshot.data().tasks; 
                            for(var j=0; j<userTaskList.length; j++){
                                userTasks.push(userTaskList[j]);
                            }
                            userTasks.push(props.task_info.id);
                            firestore.update({collection: 'users', doc: userLoacal.id}, {tasks: userTasks})
                        })

                        var taskUsersList = []//new Array();
                            for(var j=0; j<props.task_info.users.length; j++){
                                taskUsersList.push(list.users[j]);
                            }
                            taskUsersList.push(userLoacal.id);
                            firestore.update({
                                collection: props.task_info.type, 
                                doc: props.task_info.id}, {
                                users: taskUsersList
                            }).then(()=>{
                                dispatch({
                                    type: 'USERSTOTASK_ADD_SUCCESS',
                                    payload: false
                                });
                            })
                    }
                    //add this user to task & task to this user
                })

            }
            else{
                //USERSTOTASK_ADD_CANCEL
                alert("User " + props.users[i].name + " already in this task");
                dispatch({
                    type: 'USERSTOTASK_ADD_CANCEL',
                    payload: false
                });
            }
        }
        */
        
    }
}