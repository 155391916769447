import React, {Component} from 'react';
import styled from 'styled-components';
import {rgba} from 'polished'
import {mixins} from "../../mixins";

class ContextMenuOption extends Component {
    render() {
        return (
            <div className={this.props.className} onClick={this.props.action}>
                {this.props.children}
            </div>
        )
    }
}

export default styled(ContextMenuOption)`
    padding: 0 10px;
    line-height: 30px;
    white-space: nowrap;
    cursor: default;
    user-select: none;
    ${mixins.ease()};
    &:hover{
      background-color: ${p => rgba(p.theme.cBlack, 0.1)};
    }
`;