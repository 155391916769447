import React, {Component} from 'react';
import styled from 'styled-components';

class ItemLabel extends Component {
    render() {
        return (
            <div className={this.props.className} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

export default styled(ItemLabel)`
    flex-grow: 1;
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
    user-select: none;
    color: ${p => p.checked ? p.theme.cLight : ''};
    text-decoration: ${p => p.checked ? 'line-through' : 'none'};
`;