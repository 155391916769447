import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance, actionTypes } from 'redux-firestore';
import {ThemeProvider} from 'styled-components';

import mainTheme from './themes/mainTheme';
import 'moment/min/locales.min';
import fbConfig from './config/fbConfig';
import store from './store';
import App from "./components/App";

if((window.location.hostname!=='app.calendays.com')&&(window.location.hostname!=='localhost')){
    const wrongUrl = window.location.href;
    const newUrl = new URL(wrongUrl);
    newUrl.hostname = 'app.calendays.com';
    window.location.replace(newUrl.href);
}
fbConfig.functions();
fbConfig.firestore();
const rrfProps = {
    firebase: fbConfig, 
    config: {
        userProfile: 'users',
        //attachAuthIsReady: true,
        useFirestoreForProfile: true,
        onAuthStateChanged: (authData, firebase, dispatch) => {
            // Clear redux-firestore state if auth does not exist (i.e logout)
            if (!authData) {
              dispatch({ type: actionTypes.CLEAR_DATA })
            }
         }
    },
    dispatch: store.dispatch,
    createFirestoreInstance
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <ThemeProvider theme={mainTheme}>
                <App/>
            </ThemeProvider>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('app'));  
    window.addEventListener('build', function (e) {
});

