import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../../actions/calendarActions";
import moment from "moment";
import measureScrollbar from '../../misc/measureScrolbar'

const DayLabel = styled.div`
  flex-basis: ${1 / 7 * 100}%;
  font-size: ${p => p.theme.sFontBig}px;
  text-transform: capitalize;
  display: flex;
  align-items: baseline;
  padding-left: 10px;
`;

const Date = styled.div`
  height: 1.6em;
  width: 1.6em;
  border-radius: 50%;
  background-color: ${p => p.same ? p.theme.cAccent : 'transparent'};  
  color: ${p => p.same ? p.theme.cWhite : p.theme.cBlack};  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5em;
`;

const Row = styled.div`
  padding-left: 52px;
  padding-right: ${measureScrollbar()}px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.05);
  padding-top: 10px;
  padding-bottom: 10px;
`;

class TopRow extends Component {

    getWeek = () => {
        moment.locale(this.props.currentLanguage);
        const now = moment(this.props.globalNow);
        const startOfWeek = now.clone().add(this.props.weekShift, 'weeks').startOf('week');
        let days = [];
        let currentDay = startOfWeek;
        for (let i = 0; i < 7; i++) {
            days.push(currentDay);
            currentDay = currentDay.clone().add(1, 'd');
        }
        return days.map((value, key) => {
            const same = value.isSame(now, 'day');
            return <DayLabel key={key}><span>{value.format('ddd')}</span><Date same={same}>{value.format('DD')}</Date></DayLabel>
        })
    };

    render() {
        return (
            <Row className={this.props.className}>
                {this.getWeek()}
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentLanguage: state.state.get('currentLanguage'),
        weekShift: state.calendar.get('weekShift'),
        globalNow: state.calendar.get('now'),
       /* currentLanguage: state.getIn(['state', 'currentLanguage']),
        weekShift: state.getIn(['calendar', 'weekShift']),
        globalNow: state.getIn(['calendar', 'now']),*/
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(TopRow));