import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import * as Actions from "../../actions/calendarActions";
import moment from "moment";

const MonthLabel = styled.div`
  font-size: 24px;
  text-transform: capitalize;
  margin-right: 10px;
`;

class CalendarNav extends Component {

    handleShift = (inc) => {
        this.props.shiftWeek({shift: (this.props.weekShift + inc)});
    };

    getMonth = () => {
        moment.locale(this.props.currentLanguage);
        const now = moment();
        const startOfWeek = now.clone().add(this.props.weekShift, 'weeks').startOf('week');
        return startOfWeek.format('MMMM');
    };

    render() {

        return (
            <div className={this.props.className}>
                <MonthLabel>{this.getMonth()}</MonthLabel>
                <button onClick={() => this.handleShift(-1)}>{'<'}</button>
                <button onClick={() => this.handleShift(1)}>{'>'}</button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        weekShift: state.calendar.get('weekShift'),
        currentLanguage: state.state.get('currentLanguage'),
        /*weekShift: state.getIn(['calendar', 'weekShift']),
        currentLanguage: state.getIn(['state', 'currentLanguage']),*/
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        shiftWeek: Actions.shiftWeek,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(styled(CalendarNav)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`));