import React, {Component} from 'react';
import styled from 'styled-components';
import {mixins} from "../../mixins";

const Button = styled.div`
  height: 10px;
  width: 10px;
  cursor: ${p => p.active ? 'auto' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  &:before{
    ${mixins.pseudoToBlock()};
    width: ${p => p.active ? 6 : 4}px;
    height: ${p => p.active ? 6 : 4}px;
    border-radius: 50%;
    background-color: ${p => p.active ? p.theme.cAccent : p.theme.cLight};
    ${mixins.ease()};
  }
  &:hover{
    &:before{
      background-color: ${p => p.theme.cAccent};
    }
  }
`;

class Breadcrumbs extends Component {
   
  render() {
        if (this.props.list.length > 1) {
            const listLen = this.props.list.length;
            const list = this.props.list.map((item, i) => {
                return (
                    <Button onClick={() => {
                      if(listLen !== i + 1){
                        this.props.selectTask(item)
                      }
                    }} active={item === this.props.activeID} key={i}/>
                )
            });
            return (
                <div className={this.props.className}>
                    {list}
                </div>
            )
        }
        else {
            return ('');
        }
    }
}

export default styled(Breadcrumbs)`
  display: flex;
  align-items: center;
  margin-left: ${p => p.theme.itemHeight - 3}px;
  position: absolute;
  bottom: -10px;
`;