import React, {Component} from 'react';
import Header from './Header'
import TaskManager from './TaskManager'
import styled from 'styled-components'
import ContextMenu from "./contextMenu/ContextMenu";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { compose } from 'redux'
import {toJS} from "../to-js";
import { firestoreConnect } from 'react-redux-firebase'
import * as ContextMenuActions from "../actions/contextMenuActions";
import * as DatepickerActions from '../actions/datepickerActions';
import Datepicker from "./common/Datepicker";
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import Calendar from "./Calendar";

class Layout extends Component {
    
    handleClick = () => {
        if(this.props.contextMenuOpen){
            this.props.closeCM();
        }
        if(this.props.datepickerOpen){
            this.props.closeDP();
        }
    }

   
    render() {
        const {mainUser, userError, isReady} = this.props;
        if(!mainUser && !userError && isReady) {
            return(
                <div className={this.props.className} onClick={this.handleClick}>
                    <Header />
                    <p>Loading placeholder</p>
                    <ContextMenu />
                    <Datepicker />
                </div>
            )
          }
          else{
            if (userError) return <Redirect  to='/login'/>
            return (
                <div className={this.props.className} onClick={this.handleClick}>
                    <Header />
                        <Switch>
                            <Route path={'/calendar'} component={Calendar} exact/>
                            {<Route path={'/task-manager'} component={TaskManager} exact/>}
                            {<Route path={'/'}>{<Redirect to={'/task-manager'}/>}</Route>}
                        </Switch>
                    <ContextMenu />
                    <Datepicker />
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    const users = state.firestore.ordered.mainUser;
    const userError = state.firestore.errors.byQuery.mainUser
    const user = users ? users[0] : null;
    const isReady = state.firebase.auth?true:false;
    return {
        contextMenuOpen: state.context.get('open'),
        datepickerOpen: state.datepicker.get('open'),
        mainUser: user,
        userError: userError,
        isReady
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeCM: ContextMenuActions.closeContextMenu,
        closeDP: DatepickerActions.closeDatepicker,
    }, dispatch)
}
 

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props, state) => {
        return[
            { collection: 'users', doc: props.main_user_uid, storeAs: 'mainUser'},
        ]
        //const authUid = uid?uid:'111111111111111111wrong11111111'
    
  })
)(toJS(styled(Layout)`
  height: 100%;
  width: 100%;
  font-family: Roboto, 'sans-serif';
  display: flex;
  flex-direction: column;
  font-size: ${p => p.theme.sFontRegular}px;
  overflow: hidden;
`));