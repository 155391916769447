import {fromJS} from 'immutable';


export function openDatepicker(event, callback) {
    event.preventDefault();
    return ({
        type: 'DP_OPEN',
        payload: fromJS({
            position: {
                x: event.clientX,
                y: event.clientY,
            },
            callback: callback,
        })
    })
}

export function closeDatepicker() {
    return ({
        type: 'DP_CLOSE',
        payload: '',
    })
}