const defState = {
    
};

export default function reducer(state = defState, action){
    switch(action.type){
        case "INVITEPROCEED_PROCEED": {
            return {...state, inviteLoading:action.payload.loading, inviteMsg:action.payload.msg};
        }
        case "INVITEPROCEED_END": {
            return {...state, inviteLoading:action.payload.loading, inviteMsg:action.payload.msg};
        }
        default: 
            return state;
    }
}