import React, {Component} from 'react';
import styled from 'styled-components'
import {rgba} from 'polished'


class CollectionItem extends Component {
    render() {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        )
    }
}

export default styled(CollectionItem)`
  position: relative;
  background-color: ${p => !p.subtask ? rgba(p.theme.cBg, 1) : 'none'};
  overflow: hidden;
  padding-bottom: ${p => p.separated ? 0 : 1}px;
  margin-bottom: ${p => !p.separated ? 0 : 1}px;
  border-radius: ${p => p.separated ? p.theme.borderRadius : 0 }px;
  &:after{
    display: ${p => p.separated || p.subtask ? 'none' : 'block'};
    content: '';
    left: 8px;
    right: 8px;
    width: auto;
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: ${p => p.theme.cLight};
  }
  &:first-child{
    border-top-left-radius: ${p => p.theme.borderRadius}px;
    border-top-right-radius: ${p => p.theme.borderRadius}px;
  }
  &:last-child{
    border-bottom-left-radius: ${p => p.theme.borderRadius}px;
    border-bottom-right-radius: ${p => p.theme.borderRadius}px;
    padding-bottom: 0;
    &:after{
      display: none;
    }
  }
`;