import React, {Component} from 'react';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import styled from 'styled-components';
import PersonCircle from './PersonCircle';
import AddPerson from './AddPerson'
import Loader from '../common/Loader';
import * as TasksActions from '../../actions/tasksActions';


const PeopleContainerSt = styled.div`
    height: ${p => p.theme.itemHeight}px;
`;

const PersonCircleContainer = styled.div`
  padding-left: 8px;
  padding-top: 9px;
`;
const PersonCounter = styled.div`
    text-align: center;
    top: 9px;
    height: ${p => p.theme.itemHeight-18}px;
    width: ${p => p.theme.itemHeight-18}px;
    /*left: 8px;*/
    right: 50px;
    border-radius: 50%;
    position: absolute;

    display: inline-block;
    
    background-color: #F2F4F5;

    border-radius: 50%;
`;
class PeopleContainer extends Component {
  /*constructor() {
    super();
    this.state =  {
      users: [],
      usersList: [],
      width: 0,
      len: 0
    }
    //this.updateDimensions = this.updateDimensions.bind(this);
  }*/
  state =  {
    users: [],
    usersList: [],
    width: 0,
    len: 0
  }
  setLeadUserHandler = (newLeadId) =>{
    console.log('sdhfs898274923792837492');
    this.props.setLead(this.props.taskObj, newLeadId);
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    //window.addEventListener("newElm", this.updateDimensions);
    console.log(window);
  }
 
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    //window.removeEventListener("newElm", this.updateDimensions);
  }
 
  componentDidUpdate(prevProps, prevSate) {
    if (prevProps !== this.props) {
      this.updateDimensions();
    }
  } 
  updateDimensions = () => {
    const width = this.div.clientWidth;
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    if(!this.props.users){
      return <Loader/>
    }
    //console.log(this.props.users.length + ' ' + this.state.len);
    //if(this.state.width != width || this.props.users.length != this.state.len){
      console.log("props");
      console.log(this.props.users);
      const maxElm = Math.round((width-82)/41);
      const {lead_id} = this.props.taskObj;
      const oldUsers = [...this.props.users.filter(useritem=>useritem.id===lead_id), ...this.props.users.filter(useritem=>useritem.id!==lead_id)]
      const newUsers = oldUsers.map((item, i)=>{
        var maxLen = this.props.users.length
        console.log('item')
        console.log(item)
        if(maxLen<=maxElm)
          return <PersonCircle 
            selectHandler={this.setLeadUserHandler} 
            isActive={lead_id===item.id} 
            userImg={item.photoURL} 
            userID={item.id}
            key={item.id}/> 
        else
        {
          if(i<(maxElm-1))
            return <PersonCircle
              selectHandler={this.setLeadUserHandler}  
              isActive={lead_id===item.id} 
              userImg={item.photoURL} 
              userID={item.id}
              key={item.id}/>
          if(i==maxElm)
            return <PersonCounter key={i}>{maxLen-(maxElm-1)}</PersonCounter>
        }
      })
      console.log(width)
      console.log(maxElm)
      this.setState({
        ...this.state,
        width: width,
        usersList:  newUsers,
        len: this.props.users.length
      })
    //}
  }
  render(){
    console.log('333333333333333')
    console.log(this.props)
    const {taskObj, users, list_id} = this.props;
    const {usersList} = this.state;
      return(
          <PeopleContainerSt ref={div => {this.div = div}}>    
            {/*<SizeMe>
            {({ size }) => this.calcContent(size.width)/*<CalcContent width={size.width} elements={this.state.users}/>*//*}
            </SizeMe>*/}
            <PersonCircleContainer>
              {usersList}
            </PersonCircleContainer>
            <AddPerson taskinfo={taskObj} listid={list_id} users={users}/> 
          </PeopleContainerSt>
      )
  }
}

//export default PeopleContainer;
function mapStateToProps(state, props) {
  
    const objFields = state.firestore.ordered.taskUsers;
    console.log(state.firestore.ordered);
    const usersTmp = (objFields==null) ? [] : objFields;//Object.entries(objFields);
    
    console.log('11111111111111111111111111');
    console.log(props);
    console.log(usersTmp);
    //let event = new Event("newElm");
    //window.dispatchEvent(event);
    const taskObj = state.firestore.ordered.tasks.find((item)=>item.id===props.task_id);
    const taskObjUsers = taskObj.users? taskObj.users : [];
    const users = usersTmp ? usersTmp.filter((userItem)=>taskObjUsers.includes(userItem.id)) : null;
    console.log(taskObj);
    console.log(users);
    return {
        users: users, 
        taskObj: taskObj
    };
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
      setLead: TasksActions.setResponsiblePersonToTask,
  }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    /*firestoreConnect((props, state) => {
      console.log('22222222222222222222222222222');
      console.log(state);
        return[          
        { 
          collection: 'users', 
          where: [
            ['lists', 'array-contains', props.list_id]
          ],
          storeAs: 'taskUsers'
        },
    ]})*/
  )(PeopleContainer);