import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import * as InvitesActions from '../../actions/invitesActions';
import { createGlobalStyle } from 'styled-components'
import {BeatLoader} from "react-spinners";

class InviteProceed extends Component {

    proceedInvite = () => {
        this.props.proceedInvite({
            inviteID: this.props.match.params.id,
            action: this.props.match.params.action
        });
    }

    componentDidMount() {
      if((this.props.match.params.action === 'accept' || this.props.match.params.action === 'decline')&&(this.props.auth)){
        this.proceedInvite();
      }

    }
//if (!this.props.auth.uid) return <Redirect to='/login'/>
    render() {
        console.log('PROOOOOOOPPPPSS_OF_INVITE');
        console.log(this.props);
    //if (!this.props.auth.uid) return <Redirect to='/login'/>
    const Page = styled.div`
      width: 300px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 50px;
      padding: 20px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-radius: 5px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `;
    const CalendaysLogo = styled.div`
      text-align: center;
      max-width: 100%;
      margin-bottom: 30px;
    `;
    const ButtonContainer = styled.div`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
      justify-content: center;
      display: flex;
      box-pack: center;
    `;
    const GoogleSignButton = styled.button`
      display: inline-block;
      background: rgb(209, 72, 54);
      color: rgb(255, 255, 255);
      width: 190px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const BackHome = styled.a`
      display: block;
      text-align: center;
      margin-top: 30px;
      color: rgb(0, 137, 209);
      text-decoration: underline;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const GlobalStyle = createGlobalStyle`
      body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        background: url('/img/bg.png') no-repeat;
        background-size: auto;
        background-position: center;
        background-color: #274246;
        min-height: 100%;
      }
    `;
    const MessageLink = styled.span`
      display: block;
      text-align: center;
      color: rgb(0, 137, 209);
      text-decoration: underline;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const MessageSpan = styled.span`
      display: block;
      text-align: center;
      color: #AAB4BE;
      //text-decoration: underline;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    
        const renderMsg = (message)=>{
          return message?
            message.type==='t'?
              <Fragment>
                <MessageSpan>{message.data+' '}</MessageSpan>
                {
                  this.props.auth.uid?
                    <Link to='/task-manager'>
                      <MessageLink>Go to tasks</MessageLink>
                    </Link>:undefined
                }
              </Fragment>:
                <Fragment>
                  <MessageSpan>{`Please, `}</MessageSpan>
                  <Link to={message.altdata}>
                    <MessageLink>{message.data}</MessageLink>
                  </Link>
              </Fragment>:
            <MessageSpan>Loading...</MessageSpan>
        }
        const renderFnc = (message) => {
          //console.log(message)
            return (
                <Page > 
                  {<GlobalStyle/>}
                  <CalendaysLogo>
                    <img src="/img/login-logo.svg" alt='calendays'/>
                  </CalendaysLogo>
                  <ButtonContainer>
                    {
                      this.props.inviteLoading&&!message.type ? 
                        <BeatLoader
                        color={'#AAB4BE;'}/>:
                        <React.Fragment>
                          {renderMsg(message)}
                         </React.Fragment>
                    }
                  </ButtonContainer>
                  {/*
                    !this.props.auth.uid?
                      <BackHome href='http://app.calendays.com/login'>Login</BackHome> 
                    :undefined*/
                  }
                  <BackHome href='http://www.calendays.com/'>Back home</BackHome> 
                </Page>
              )
        }; 
        if(this.props.match.params.action === 'accept' || this.props.match.params.action === 'decline'){
            return renderFnc(this.props.inviteMsg);
        }
        else{
            return renderFnc('Unexcepted action');
        }
    }
}

function mapStateToProps(state) {
  console.log(state.invites.inviteMsg);
  return {
      auth: state.firebase.auth,
      inviteLoading: state.invites.inviteLoading,
      inviteMsg: state.invites.inviteMsg,
  };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        proceedInvite: InvitesActions.proceedInvite
    }, dispatch)
} 

export default connect(mapStateToProps, mapDispatchToProps)(InviteProceed);

