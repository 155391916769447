import React, {Component, Fragment} from 'react';
import {bindActionCreators} from "redux";
import * as LoginActions from '../../actions/loginActions';
import connect from "react-redux/es/connect/connect";
import {toJS} from "../../to-js";
import {Redirect} from "react-router-dom";
import GoogleLogin from "react-google-login";
import styled from 'styled-components'
import Loader from "../common/Loader";
import { createGlobalStyle } from 'styled-components'
import { BeatLoader } from 'react-spinners';
import Mailto from 'react-mailto';

const Page = styled.div`
      width: 300px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 50px;
      padding: 20px;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-radius: 5px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `;
    const CalendaysLogo = styled.div`
      text-align: center;
      max-width: 100%;
      margin-bottom: 30px;
    `;
    const ButtonContainer = styled.div`
      justify-content: center;
      display: flex;
      box-pack: center;
    `;
    const GoogleSignButton = styled.button`
      display: inline-block;
      background: rgb(209, 72, 54);
      color: rgb(255, 255, 255);
      width: 190px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const BackHome = styled.a`
      display: block;
      text-align: center;
      margin-top: 30px;
      color: rgb(0, 137, 209);
      text-decoration: underline;
      @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
      font-family: Roboto;
    `;
    const GlobalStyle = createGlobalStyle`
      body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        background: url('/img/bg.png') no-repeat;
        background-size: auto;
        background-position: center;
        background-color: #274246;
        min-height: 100%;
      }
    `;

class Login extends Component {
    /*constructor(props) {
        super(props);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    }*/
    state = {
      loading: false
    }

    handleGoogleLogin = (response) => {
      this.setState({
        loading: true
      })
      if(!this.props.auth.uid)
        this.props.googleLogin(response.tokenId);
    }
    /*componentWillReceiveProps*/
    componentDidUpdate(prevProps) {
      const {auth, loggedIn} = this.props;
      if(auth.uid&&loggedIn===true){
        this.setState({loading: false})
      }
      if (prevProps.location !== this.props.location) {
        this.setState({ prevPath: this.props.location })
      }
    }
    render() {
      const {inProgress, auth, loggedIn, authError} = this.props;
      if (auth.uid&&loggedIn===true) {
        return <Redirect to='/task-manager'/>
     
      }
      const maillink = (
        <Mailto 
          style={{
            color: `rgb(0, 137, 209)`,
            textDecoration: `underline`,
            fontFamily: `Roboto`
          }}
          headers="subject=Beta program qualification" 
          email="info@calendays.com" 
          obfuscate={true}>
            contact support
        </Mailto>
      );
      // console.log(inProgress);
      return (
        <Page >
          
          <GlobalStyle/>
            <CalendaysLogo>
              <img src="/img/login-logo.svg" alt='calendays'/>
            </CalendaysLogo>
            <ButtonContainer>
            {
              inProgress ? 
                <BeatLoader
                  color={'#AAB4BE;'}/>:
                authError?
                  (authError==='Account not accepted')?
                    <span 
                    style={{
                      fontFamily: `Roboto`
                    }}>Sorry, but your account is not quilified for our Beta version. Please {maillink} if you think this is a mistake.</span>:
                    <p 
                    style={{
                      fontFamily: `Roboto`
                    }}>{authError}</p>:
                  <GoogleSignButton onClick={this.handleGoogleLogin}>Login with Google</GoogleSignButton>
              
            }
                
            </ButtonContainer>
              <BackHome href='http://www.calendays.com/'>Back home</BackHome> 
            </Page>
      )
    }
}

function mapStateToProps(state) {
  //console.log(state);
  const {inProgress, authError} = state.authreducer;
    return {
      inProgress: inProgress,
        auth: state.firebase.auth,
        loggedIn: state.authreducer.loggedIn,
        authError: authError, 
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login: LoginActions.login,
        googleLogin: LoginActions.googleLogin,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Login));

