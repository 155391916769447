import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components';
import TaskNav from "./tasks/TaskNav"
import LogoutButton from "./login/LogoutButton";
import CalendarNav from "./calendar/CalendarNav";
import {Route, Link, withRouter} from "react-router-dom";
import { padding } from 'polished';

/*
const AppHeader = styled.div`
  width: 100%;
  height: 90px;
  padding: 15px 0;
  box-sizing: border-box;
  background: #fff;
`;

const AppHeaderWrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
`;
const MiddleColWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
const LastColWrapper = styled.div`
  box-sizing: content-box;
  padding: 0 ${p => p.theme.gutter}px;
  width: ${p => p.theme.sTaskWidth}px;
  display: flex;
  align-items: center;
  border-left: 1px solid ${p => p.theme.cBorder};
`;
*/
//position: absolute;
const AppHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 90px;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
`;

const Left = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    width: 316px;
    height: 100%;
`;

const Middle = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    height: 100%;
`;

const Right = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    width: 416px;
    height: 100%;
`;

const LeftWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
    border-right: 1px solid #B2B2B2;
`;

const BtnGroupCol1 = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 32px;
    width: 128px;
    padding-left: 16px;
`;

const CircleBtn = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #AAB4BE;
`;

const BtnGroupCol2 = styled.div`
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    height: 32px;
    width: 140px;
`;

const IconLeft = styled.div`
    display: flex;
    width: 14px;
    height: 13px;
    background-color: #AAB4BE;
    margin-left: 23px;
`;

const IconRight = styled.div`
    display: flex;
    width: 14px;
    height: 16px;
    background-color: #AAB4BE;
    margin-right: 30px;
`;

const SwipeBtn = styled.div`
    display: flex;
    width: 44px;
    height: 22px;
    border-radius: 22px 22px 22px 22px;
    background-color: #AAB4BE;
`;

const MiddleWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    height: 50px;
    width: 100%;
`;

const ListContainerCol1 = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    width: 247px;
`;

const ListIconLeft = styled.div`
    display: flex;
    position: relative;
    width: 19px;
    height: 15px;
    margin-left: 24px;
    margin-right: 14px;
    background-color: #AAB4BE;
`;

const ListName = styled.span`
    display: flex;
    position: relative;
    font-family:  Roboto, sans-serif;
    font-weight: 300;
    font-size: 16px;
`;

const ListContainerCol2 = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

const ListIconRight = styled.div`
    display: flex;
    position: relative;
    width: 19px;
    height: 15px;
    margin-right: 19px;
    background-color: #AAB4BE;
`;


const RightWrapper = styled.div`
    display: flex;
    position: relative;
    height: 50px;
    width: 100%;
    border-left: 1px solid #B2B2B2;
`;

class Header extends Component {
    render() {
        const {location:{pathname}, listTitle, mainUser} = this.props;
        //console.log(this.props)
        return (
            /*
            <AppHeader>
                <AppHeaderWrapper>
                    <MiddleColWrapper>
                        <LogoutButton/>
                        {pathname==='/calendar'?
                            <Link to="/task-manager" style={{padding: "5px"}}>To task manager</Link>:
                            pathname==='/task-manager'?<Link to="/calendar" style={{padding: "5px"}}>To calendar</Link>:
                            <span></span>
                        }
                    </MiddleColWrapper>
                    <LastColWrapper>
                        {<Route path={'/task-manager'} component={TaskNav}/>}
                        <Route path={'/calendar'} component={CalendarNav}/>
                    </LastColWrapper>
                </AppHeaderWrapper>
            </AppHeader>
            */
            <AppHeader>
                <Left>
                    <LeftWrapper>
                        <BtnGroupCol1>
                            {
                                (mainUser)?
                                <Fragment>
                                        <LogoutButton/>
                                    <CircleBtn/>
                                    <CircleBtn/>
                                </Fragment>:
                                undefined

                            }
                        </BtnGroupCol1>
                        <BtnGroupCol2>
                            <Link to="/calendar"><IconLeft/></Link>
                            {
                                pathname==='/calendar'?    
                                <Link to="/task-manager" ><SwipeBtn/></Link>:
                                pathname==='/task-manager'?<Link to="/calendar"><SwipeBtn/></Link>:
                                <span></span>
                            }
                            <Link to="/task-manager" ><IconRight/></Link>
                        </BtnGroupCol2>
                    </LeftWrapper>
                </Left>
                <Middle>
                    <MiddleWrapper>
                        <ListContainerCol1>
                            {
                                listTitle?
                                <Fragment>
                                    <ListIconLeft/>
                                    <ListName>
                                        {listTitle}
                                    </ListName>
                                </Fragment>
                                :undefined
                            }
                        </ListContainerCol1>
                        <ListContainerCol2>
                            {
                                listTitle?
                                    <ListIconRight/>
                                :undefined
                            }
                        </ListContainerCol2>
                    </MiddleWrapper>
                </Middle>
                <Right>
                    <RightWrapper>
                        {<Route path={'/task-manager'} component={TaskNav}/>}
                        <Route path={'/calendar'} component={CalendarNav}/>
                    </RightWrapper>
                </Right>
            </AppHeader>
        )
    }
}

function mapStateToProps(state) {
    const {activeID} = state.lists;
    const activeListID = activeID?activeID[0].toString():undefined;
    //console.log(activeID);
  //const stateList = state.firestore.ordered.lists;
    const listTitle = (activeListID)? activeID[1].title: undefined;
    const users = state.firestore.ordered.mainUser;
    const user = users ? users[0] : null;
    return {
        mainUser: user,
        listTitle: listTitle
    };
}

export default connect(mapStateToProps)(withRouter(Header));