import {fromJS} from 'immutable';


export function openContextMenu(event, options) {
    event.preventDefault();
    return ({
        type: 'CM_OPEN',
        payload: fromJS({
            position: {
                x: event.clientX,
                y: event.clientY,
            },
            options: options,
        })
    })
}

export function closeContextMenu() {
    return ({
        type: 'CM_CLOSE',
        payload: '',
    })
}