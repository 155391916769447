import {Map, List} from 'immutable';

const defState = Map({
    open: false,
    position: Map({
        x: 0,
        y: 0,
    }),
    options: List(),
});

export default function reducer(state = defState, action) {

    switch (action.type) {
        case "CM_OPEN": {
            return state
                .set('open', true)
                .set('position', action.payload.get('position'))
                .set('options', action.payload.get('options'));
        }
        case "CM_CLOSE": {
            return state
                .set('open', false)
                .set('position', Map({x: 0, y: 0}))
                .set('options', List());
        }
        default:
            return state;
    }
};