import {Map, List} from 'immutable';

const defState = {
    lists: {},
    activeID: 0,
    listsLoading: false,
};
 
export default function reducer(state = defState, action) {
    switch (action.type) {
        /*case "LISTS_LOAD_PENDING": {
            return state.set('listsLoading', true);
        }
        case "LISTS_LOAD_SUCCESS": {
            return state
                .set('lists', action.payload.get('data'))
                .setIn(['inbox', 'count'], action.payload.getIn(['preset', 'inbox', 'count']))
                .set('listsLoading', false);
        }
        case "LISTS_LOAD_ERROR": {
            return state
                .set('listsLoading', false);
        }*/
        /*case "LIST_ADD_PENDING": {
            return state;
        }
        case "LIST_ADD_SUCCESS": {
            return state.update('lists', lists => lists.push(action.payload.get('data')));
        }
        case "LIST_ADD_ERROR": {
            return state;
        }*/
        case "LIST_DELETE_PENDING": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_DELETE_SUCCESS": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_DELETE_ERROR": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_ADD_PENDING": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_ADD_SUCCESS": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_ADD_ERROR": {
            return {...state, listLoadingAlt:action.payload};
        }
        case "LIST_SELECT": {
            //state.set('tasks').tasksLoading = true;
            console.log(state);
            return {...state, activeID:action.payload};
        }
        case "LIST_INC": {
            if (action.payload.get('id') === 0 || action.payload.get('id') === "0") {
                return state.setIn(['inbox', 'count'], (state.getIn(['inbox', 'count']) + action.payload.get('amount')));
            }
            else {
                const index = state.get('lists').findIndex(list => {
                    return list.get('id') === action.payload.get('id');
                });
                return state.updateIn(['lists', index], list => list.set('count', (parseInt(list.get('count'), 10) + action.payload.get('amount'))));
            }
        }
        default:
            return state;
    }
};