import {combineReducers} from "redux";
import tasks from "./tasksReducer";
import lists from "./listsReducer";
import context from "./contextMenuReducer";
import datepicker from "./datepickerReducer";
import calendar from "./calendarReducer";
import state from "./stateReducer";
import authReducer from './authReducer';
import usersToTask from './usersToTaskReducer';
import invites from './invitesReducer';

import {firestoreReducer} from 'redux-firestore'
import {firebaseReducer} from "react-redux-firebase";

export default combineReducers({
    tasks,
    lists,
    context,
    datepicker,
    calendar,
    state,
    usersToTask,
    invites,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    authreducer: authReducer
});