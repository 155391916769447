import React, {Component} from 'react';
import styled from 'styled-components';


const PersonCircleSt = styled.div`
    text-align: center;
    /*top: 9px;*/
    height: ${p => p.theme.itemHeight-18}px;
    width: ${p => p.theme.itemHeight-18}px;
    /*left: 8px;*/
    /*margin-left: 8px;*/
    margin-right: 8px;
    border-radius: 50%;
    position: relative;

    display: inline-block;
    
    background-image: url(${(props)=>props.imgUrl}); 
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
`;

const PersonContour = styled.div`
    position: absolute;
    height: ${p => p.theme.itemHeight-18}px;
    width: ${p => p.theme.itemHeight-18}px;
    left: calc(50% - ${p => p.theme.itemHeight-18}px/2);
    top: calc(50% - ${p => p.theme.itemHeight-18}px/2);
    border-radius: 50%;
    opacity: 0.05;
    /* Black */

    border: 1px solid #000000;
    box-sizing: border-box;
    &:hover{
        background: #0095FF;
        opacity: 0.5;
        transition-duration: 0.3s;
    }
`;


const PersonContourActive = styled.div`
    position: absolute;
    height: ${p => p.theme.itemHeight-14}px;
    width: ${p => p.theme.itemHeight-14}px;
    left: calc(50% - ${p => p.theme.itemHeight-14}px/2);
    top: calc(50% - ${p => p.theme.itemHeight-14}px/2);
    border-radius: 50%;


    border: 1px solid #0095FF;
    box-sizing: border-box;
    &:hover{
        background: #0095FF;
        opacity: 0.5;
        transition-duration: 0.3s;
    }
`;

class PersonCircle extends Component {
  /*constructor(props){
      super(props);
      this.state = {
        isActive: false
      }
  }*/
  /*changeActive = () =>{
      console.log('state: ' + this.state.isActive);
      console.log('props');
      console.log(this.props);
    this.setState({
        ...this.state,
        isActive: !this.state.isActive
    })
  }*/
  handleSelect = () => {
    if (typeof (this.props.selectHandler) === 'function') {
        this.props.selectHandler(this.props.userID);
    }
  }
  render(){
        return(
            <PersonCircleSt imgUrl={this.props.userImg}>
              <PersonContour onClick={this.handleSelect}/>
              {this.props.isActive && <PersonContourActive onClick={this.changeActive}/>}
            </PersonCircleSt>
        )
    
      
  }
}

export default PersonCircle;